import React, {useState} from "react";
import DayPlan from "./DayPlan";
import DayPlanModel from "../../model/types/DayPlanModel";
import SelectionDialog from "./SelectionDialog";
import {copyDayPlanToDate, getDayPlansForDay} from "../../model/ModelController/Planning/DayPlanControllerAdapter";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

function DayPlanWrapper() {
    const {enqueueSnackbar} = useSnackbar();

    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const [date, setDate] = useState<number | undefined>(undefined)
    const [callBackProcessed, setCallBackProcessed] = useState(false)
    const [loadedPLan, setLoadedPlan] = useState<DayPlanModel | undefined>(undefined)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [copyDialogOpen, setCopyDialogOpen] = useState(false);
    const [dayPlanExists, setDayPlanExists] = useState(false);
    const {t, i18n} = useTranslation();


    if (date === undefined) {
        if (!dialogOpen)
            setDialogOpen(true)
    }

    const chooseAgainCallBack = () => {
        setDate(undefined);
        setLoadedPlan(undefined);
        setCallBackProcessed(false);
    }

    const copyDayPlanCallBack = () => {
        setCopyDialogOpen(true);
    }

    const selectCallBack = (date: number) => {
        setDialogOpen(false)
        setDate(date)
        getDayPlansForDay(date).then((Tagesplaene) => {
            if (Tagesplaene != undefined) {
                setLoadedPlan(Tagesplaene[0])
                setCallBackProcessed(true);
                setDayPlanExists(true)
            } else {
                setDayPlanExists(false)
                setCallBackProcessed(true);
            }
        })
    }

    return (
        <div>
            {(date === undefined) ?
                <SelectionDialog open={dialogOpen}
                                 closeCallback={() => setDialogOpen(false)} selectCallback={selectCallBack}/>
                : (callBackProcessed) ?
                    <DayPlan Datum={date}
                             dayPlan={loadedPLan}
                             chooseAgainCallback={chooseAgainCallBack}
                             copyCallback={copyDayPlanCallBack}/>
                    : <div/>
            }
            {(copyDialogOpen) ?
                <SelectionDialog open={copyDialogOpen}
                                 selectCallback={(copyDate) => {
                                     let copyProcedure = async () => {
                                         if (date) {
                                             let dayPlanOg = await getDayPlansForDay(date);
                                             if (dayPlanOg.length > 0) {
                                                 try {
                                                     let targetTagesPlanModel = (await getDayPlansForDay(copyDate))[0];
                                                     if (targetTagesPlanModel) {
                                                         showSnackbar("error", t("dayPlan.copyNotPossible"))
                                                         setCopyDialogOpen(false);
                                                         return;
                                                     }
                                                     let copiedPlan = await copyDayPlanToDate(dayPlanOg[0], copyDate);
                                                     setLoadedPlan(copiedPlan);
                                                     setDate(copyDate)
                                                     setCallBackProcessed(true);
                                                     setDayPlanExists(true)
                                                     showSnackbar("success", t("dayPlan.successfullyCopied"))
                                                     setDate(copyDate);
                                                 } catch (e) {
                                                     showSnackbar("error", t("dayPlan.technicalErrorOnCopy"))
                                                 }
                                             } else {
                                                 showSnackbar("error", t("dayPlan.unsavedError"))
                                             }
                                         }
                                         setCopyDialogOpen(false);
                                     }
                                     copyProcedure();
                                 }
                                 }
                                 closeCallback={() => {
                                     setCopyDialogOpen(false)
                                 }}/>
                : <div/>}
        </div>
    )
}


export default withSnackbar(DayPlanWrapper);