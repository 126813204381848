import React, {useContext, useEffect, useState} from "react";
import ConstructionSiteModel from "../../../../model/types/basistypes/ressources/ConstructionSiteModel";
import ConstructionSiteMask from "./ConstructionSiteMask";
import {getConstructionSitesListView} from "../../../../model/ModelController/Resources/ConstructionSiteController";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {AppBarInfoAreaContext} from "../../../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../Helper/ResourcesLayout";
import ResourceList from "../Helper/ResourceList";
import {useTranslation} from "react-i18next";
import EmployeeModel from "../../../../model/types/basistypes/ressources/EmployeeModel";
import {
    getEmployeeFromLoggedInUserIfApplicableOfBeingAssignedAsConstructionManager
} from "../../../../model/ModelController/Resources/EmployeeController";
import {Prompt} from "react-router-dom";
import {containsFilterValue} from "../../../../utility/util";
import ConstructionSiteListModel from "../../../../model/types/basistypes/ressources/ConstructionSiteListModel";

function ConstructionSiteOverview(this: any) {
    const [isDirtyIndex, setIsDirtyIndex] = useState(-1)
    const [constructionSiteList, setConstructionSiteList] = useState<ConstructionSiteListModel[]>([])
    const [employeeIfApplicableOfBeingAssignedAsConstructionManager, setEmployeeIfApplicableOfBeingAssignedAsConstructionManager] = useState<EmployeeModel | undefined>()
    const [selectedConstructionSite, setSelectedConstructionSite] = useState<number>(0)
    const [constructionSitesLoaded, setConstructionSitesLoaded] = useState<boolean>(false)
    const {enqueueSnackbar} = useSnackbar();
    const appbarContext = useContext(AppBarInfoAreaContext);
    const {t} = useTranslation();

    appbarContext.updateContent(t("ManageConstructionSites"));

    function initializeOverviewData() {
        getEmployeeFromLoggedInUserIfApplicableOfBeingAssignedAsConstructionManager()
            .then(employee => {

                if (employee) {
                    setEmployeeIfApplicableOfBeingAssignedAsConstructionManager(employee)
                }

                getConstructionSitesListView()
                    .then(
                        (constructionSites: ConstructionSiteListModel[]) => {
                            setConstructionSiteList(constructionSites)
                            if (constructionSites.length > 0) {
                                if (employee) {
                                    const index = constructionSites.findIndex(constructionSite => containsFilterValue(constructionSite, employee.id.toString()));
                                    if (index !== -1) {
                                        setSelectedConstructionSite(index);
                                    }
                                } else {
                                    setSelectedConstructionSite(0)
                                }
                            }
                        })
                    .finally(() => setConstructionSitesLoaded(true))
            })
    }

    useEffect(() => {
        initializeOverviewData()
    }, []);


    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const updateCallback = (index: number) => {
        return (update: ConstructionSiteModel) => {
            const listEntry: ConstructionSiteListModel = {
                id: update.id,
                title: update.title,
                _isNew: false,
                constructionManagers: update.constructionManagers
            }
            let newState: ConstructionSiteListModel[] = [...constructionSiteList]
            newState[index] = listEntry;
            setConstructionSiteList(newState);
            setIsDirtyIndex(-1);

            showSnackbar("success", t("constructionSite.successfullyCreated", {
                title: update.title
            }))
        }
    }

    const deleteCallback = (index: number) => {
        let newState: ConstructionSiteListModel[] = constructionSiteList.filter(
            ((value, index1) => {
                return index1 !== index
            })
        )
        setConstructionSiteList(newState);
        setSelectedConstructionSite(0);
        setIsDirtyIndex(-1);

    }

    const getEmptyConstructionSite = (): ConstructionSiteListModel => {
        return {
            id: Date.now() * -1,
            title: t("constructionSite.placeholder"),
            _isNew: true,
            constructionManagers: employeeIfApplicableOfBeingAssignedAsConstructionManager?.id ? [employeeIfApplicableOfBeingAssignedAsConstructionManager?.id] : []
        }
    }

    const addConstructionSite = () => {
        if (discardChangesInputWindowConfirmDialog()) {
            let newConstructionSiteList = [...constructionSiteList];
            if (constructionSiteList[selectedConstructionSite]?._isNew) {
                newConstructionSiteList.splice(selectedConstructionSite, 1)
            }
            if (constructionSiteList === undefined) {
                let newState: ConstructionSiteListModel[] = [getEmptyConstructionSite()];
                setConstructionSiteList(newState)
                setSelectedConstructionSite(newState.length - 1)
                setIsDirtyIndex(-1);

            } else {
                newConstructionSiteList.push(getEmptyConstructionSite());
                setConstructionSiteList(newConstructionSiteList)
                setSelectedConstructionSite(newConstructionSiteList.length - 1)
                setIsDirtyIndex(-1);

            }
        }
    }

    const setIndex = (resource: any, index: number) => {
        if (selectedConstructionSite === index) {
            return;
        }
        if (discardChangesInputWindowConfirmDialog()) {
            if (constructionSiteList[selectedConstructionSite]?._isNew) {
                let newConstructionSiteList = [...constructionSiteList];
                newConstructionSiteList.splice(selectedConstructionSite, 1)
                setSelectedConstructionSite(index);
                setConstructionSiteList(newConstructionSiteList)
            }
            setSelectedConstructionSite(index);
            setIsDirtyIndex(-1);
        } else {
            //do nothing -> stay on old selected resource
        }
    }

    const discardChangesInputWindowConfirmDialog = () => {
        if (isDirtyIndex !== -1 || constructionSiteList[selectedConstructionSite]?._isNew) {
            return window.confirm(t("discardChangesDialog.text"));
        } else {
            return true;
        }
    }

    return (
        <>
            <Prompt
                when={isDirtyIndex !== -1 || constructionSiteList[selectedConstructionSite]?._isNew === true}
                message={t("discardChangesDialog.leaveOverview")}
            />
            <ResourceLayout
                left={
                    <ResourceList resources={constructionSiteList}
                                  resourcesLoaded={constructionSitesLoaded}
                                  selectedItem={selectedConstructionSite}
                                  getListItemText={(constructionSite: ConstructionSiteModel) => {
                                      return constructionSite.title + ((constructionSite._isNew || constructionSiteList[isDirtyIndex]?.id === constructionSite.id) ? "*" : "")
                                  }}
                                  initialFilterValue={employeeIfApplicableOfBeingAssignedAsConstructionManager?.id.toString()}
                                  resourceClickCallBack={setIndex}
                                  addItemCallback={addConstructionSite}/>
                }
                right={
                    (constructionSiteList.length > 0 && constructionSitesLoaded) ?
                        <ConstructionSiteMask editEnabled={true}
                                              constructionSiteId={constructionSiteList[selectedConstructionSite].id}
                                              currentEmployeeIfApplicableOfBeingAssignedAsConstructionManager={employeeIfApplicableOfBeingAssignedAsConstructionManager}
                                              updateCallback={updateCallback(selectedConstructionSite)}
                                              deleteCallback={deleteCallback}
                                              isDirtyCallback={() => setIsDirtyIndex(selectedConstructionSite)}
                                              asDialog={false}
                                              closeCallback={() => {
                                              }}/> : <div/>
                }/>
        </>)
}

export default withSnackbar(ConstructionSiteOverview)

