import {Delete, Event, FileCopy, MoreVert, OpenInNew, Print, Save} from "@mui/icons-material";
import {Action} from "../ActionBar/GenericActionBarIconButton";
import VehicleModel from "../../model/types/basistypes/ressources/VehicleModel";
import EmployeeModel from "../../model/types/basistypes/ressources/EmployeeModel";
import EquipmentModel from "../../model/types/basistypes/ressources/EquipmentModel";
import {Grid} from "@mui/material";
import DnDItemCard, {PopOverActions} from "./Helper/DnDItemCard";
import {getIdentifier} from "../../utility/util";
import React from "react";
import DayPlanModel from "../../model/types/DayPlanModel";


export const buildActions = (t: (key: string) => string | undefined, saveDayPlan: () => void, isLocked: boolean, copyCallback: () => {}, setPopupToConfirmOpen: (open: boolean) => void, chooseAgainCallback: () => void, setPrintOpen: (open: boolean) => void): Array<Action | any> => {
    const actions: Array<Action | any> = [
        {
            icon: <Save/>,
            name: "Save",
            tooltip: t("actions.save"),
            callback: saveDayPlan,
            disabled: isLocked,
            actionKey: "s"
        },
        {
            icon: <FileCopy/>,
            name: "Copy",
            tooltip: t("actions.copy"),
            callback: copyCallback,
        },
        {
            icon: <Delete/>,
            name: "Delete",
            tooltip: t("actions.delete"),
            callback: () => {
                setPopupToConfirmOpen(true);
            },
            disabled: isLocked,
        },
        {
            icon: <Event/>,
            name: "DayPlan",
            tooltip: t("actions.dayPlan"),
            callback: chooseAgainCallback,
        },
        {
            icon: <Print/>,
            name: "Print",
            tooltip: t("actions.print"),
            callback: () => {
                setPrintOpen(true)
            }
        },
    ];
    return actions;
};

export const getTeamItem = (id: number, index: number, object: VehicleModel | EmployeeModel | EquipmentModel, buttonAction: () => void, isLocked: boolean, resourceIsAvailable: (id: number) => boolean, t: (key: string) => string) => {
    return (
        <Grid xs={12} item container>
            <DnDItemCard
                title={getIdentifier(object)}
                id={id}
                index={index}
                referencedObject={object}
                deactivateDnD={isLocked}
                actions={buildTeamItemActions(buttonAction, isLocked, t)}
                available={resourceIsAvailable(id)}
            />
        </Grid>
    );
};

export const containsFilterValue = (object: any, filtervalue: string) => {
    let keys = Object.keys(object);

    if (filtervalue === "") {
        return true;
    }

    for (let key of keys) {
        let value = object[key].toString();
        if (value) {
            if (value.includes(filtervalue)) {
                return true;
            }
        }
    }
    return false;
};

export const buildTeamActions = (index: number, isLocked: boolean, localDayPlan: DayPlanModel, t: (key: string) => string, setLocalDayPlan: (dayPlan: DayPlanModel) => void, handleClickOpen: (index: number) => () => void): PopOverActions[] => {
    if (!isLocked) {
        return [
            {
                icon: <OpenInNew/>,
                actionText: t("actions.teamDialog"),
                action: handleClickOpen(index),
            },
            {
                icon: <Delete/>,
                actionText: t("actions.deleteTeam"),
                action: () => {
                    let dayPlan = {...localDayPlan};
                    let teams = localDayPlan.teams;
                    teams.splice(index, 1);
                    dayPlan.teams = teams;
                    setLocalDayPlan(dayPlan);
                },
            },
        ];
    } else {
        return [];
    }
};

export const buildEmployeeActions = (index: number, employee: EmployeeModel, isLocked: boolean, setEmployeeDialog: (obj: { open: boolean, index: number, teamIndex: number, type: string }) => void, setSelectedEmployee: (employee: EmployeeModel) => void) => {
    if (!isLocked) {
        return [
            {
                icon: <MoreVert/>,
                actionText: "",
                action: () => {
                    setEmployeeDialog({
                        open: true,
                        index: index,
                        teamIndex: -1,
                        type: "Store",
                    });
                    setSelectedEmployee(employee);
                },
            },
        ];
    } else {
        return [];
    }
};

export const buildVehicleActions = (index: number, vehicle: VehicleModel, isLocked: boolean, setVehicleDialog: (obj: { open: boolean, index: number, teamIndex: number, type: string }) => void, setSelectedVehicle: (vehicle: VehicleModel) => void) => {
    if (!isLocked) {
        return [
            {
                icon: <MoreVert/>,
                actionText: "",
                action: () => {
                    setVehicleDialog({
                        open: true,
                        index: index,
                        teamIndex: -1,
                        type: "Store",
                    });
                    setSelectedVehicle(vehicle);
                },
            },
        ];
    } else {
        return [];
    }
};

export const buildEquipmentActions = (index: number, equipment: EquipmentModel, isLocked: boolean, setEquipmentDialog: (obj: { open: boolean, index: number, teamIndex: number, type: string }) => void, setSelectedEquipment: (equipment: EquipmentModel) => void) => {
    if (!isLocked) {
        return [
            {
                icon: <MoreVert/>,
                actionText: "",
                action: () => {
                    setEquipmentDialog({
                        open: true,
                        index: index,
                        teamIndex: -1,
                        type: "Store",
                    });
                    setSelectedEquipment(equipment);
                },
            },
        ];
    } else {
        return [];
    }
};

export const buildTeamItemActions = (buttonAction: () => void, isLocked: boolean, t: (key: string) => string) => {
    if (!isLocked) {
        return [
            {
                icon: <MoreVert/>,
                actionText: t("actions.dataMaintenance"),
                action: buttonAction,
            },
        ];
    } else {
        return [];
    }
};