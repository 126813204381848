var firebaseConfig1 = {
    apiKey: "AIzaSyCcFyfhMPPoFrdFcQlquRQSYxtwgVFqvt4",
    authDomain: "arbeitsplanung-9b78a.firebaseapp.com",
    projectId: "arbeitsplanung-9b78a",
    storageBucket: "arbeitsplanung-9b78a.appspot.com",
    messagingSenderId: "1068930446126",
    appId: "1:1068930446126:web:b1e9bca999fa03e97bc0bf"
};

var firebaseConfig = {
    apiKey: "AIzaSyCSrrDJJgQ-T_LYhxYCFdMaA2k6zOT6nxc",
    authDomain: "kapion-spie-wuppertal.firebaseapp.com",
    projectId: "kapion-spie-wuppertal",
    storageBucket: "kapion-spie-wuppertal.appspot.com",
    messagingSenderId: "374573962576",
    appId: "1:374573962576:web:c42555b87a673ac7afc855"
};


export default firebaseConfig
;