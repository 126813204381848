import React, {ChangeEvent, useState} from "react";
import {getFirebase} from "../model/ModelController/FirebaseWrapper";

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Typography
} from "@mui/material";
import {GlobalStyledDialogPaper, GlobalStyledTextField} from "./StyledComponents/GlobalStyled/GlobalStyled";
import {useSnackbar, VariantType} from "notistack";
import {useTranslation} from "react-i18next";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import firebase from "firebase";
import getCompanyAffiliation from "../model/ModelController/CompanyAffiliation";


interface propsType {
    loggedInCallback: (user: firebase.User | null) => void
}

const SignIn = (props: propsType) => {
    const [open, setOpen] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    // show and hide password
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const handleChange = (prop: string) => (event: ChangeEvent<any>) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };

    const handleMouseDownPassword = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    const [email, setEmail] = useState("");
    const {t} = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogin = () => {
        {
            const auth = getFirebase().auth()
            auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
                .then(() => {
                    // Existing and future Auth states are now persisted in the current
                    // session only. Closing the window would clear any existing state even
                    // if a user forgets to sign out.
                    // ...
                    // New sign-in will be persisted with session persistence.
                    return auth.signInWithEmailAndPassword(email, values.password).then(
                        data => {
                            props.loggedInCallback(data.user);

                            getCompanyAffiliation().then(
                                data => {
                                    console.log(data)
                                }
                            )


                        }
                    ).catch((e => {
                        showSnackbar("error", t("signIn.loginError") + "")
                    }))


                        ;
                })
                .catch((error) => {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;


                });
            handleClose()

        }
    }

    return (

        <Grid container
              alignItems={"center"}
              justifyContent={"center"}
              xs={12}
              sx={{height: "100vh", backgroundColor: "paper.dark"}}
        >
            <Grid item container xs={5}>
                <GlobalStyledDialogPaper elevation={5}
                                         sx={{
                                             padding: 5,
                                             backgroundColor: "paper.light",
                                         }}>
                    <Grid
                        container
                        direction={'row'}
                        sx={{width: "100%", height: "100%"}}
                        xs={12}
                        spacing={1}>
                        <Grid container item xs={12} sm={12} md={12} justifyContent={"center"}
                              alignItems={"center"}>
                            <img src={"/Logo_Final_2022.png"}
                                 style={{width: "27vw"}}/>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={12} justifyContent={"flex-end"}
                              alignItems={"center"}
                              spacing={2}>
                            <Grid item container xs={12}
                                  justifyContent={"flex-end"}>
                                <Grid item container xs={12} justifyContent={"flex-end"}>
                                    <GlobalStyledTextField label={"Login"}
                                                           sx={{
                                                               m: 0, p: 0,
                                                               width: "100%"
                                                           }}
                                                           variant="outlined" onChange={(event => {
                                        setEmail(event.currentTarget.value)
                                    })}/>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} justifyContent={"flex-end"}>
                                <Grid item container xs={12} justifyContent={"flex-end"}>
                                    <FormControl
                                        variant="outlined"
                                        sx={{
                                            width: "100%",
                                        }}
                                    >

                                        <InputLabel
                                            htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <Visibility/> :
                                                            <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <Link color={"secondary.main"} sx={{"cursor": "pointer"}} onClick={
                                                    () => {
                                                        const auth = getFirebase().auth()
                                                        auth.sendPasswordResetEmail(email).then(() => {
                                                            showSnackbar("success", t("signIn.sendPasswordForget", {email: email}) + "")
                                                        })
                                                    }
                                                }>{t("signIn.passwordForget")}</Link>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item container xs={12}
                                          justifyContent={"flex-end"}
                                          alignContent={"flex-end"}>
                                        <Grid item container xs={6} justifyContent={"flex-end"} alignItems={"flex-end"}>
                                            <Button variant="contained" color="primary" onClick={handleLogin}>
                                                <Typography>{t("signIn.login")} </Typography> </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </GlobalStyledDialogPaper>
                        </Grid>
                    </Grid>
    )
}

export default SignIn
