import useAvailability from "../AvailabilityHook";
import React, {useEffect, useState} from "react";
import DayPlanModel from "../../model/types/DayPlanModel";
import {getDayPlansForRange} from "../../model/ModelController/Planning/DayPlanControllerAdapter";
import {validateTeamConfiguration} from "../Planning/Helper/Validator";
import {Box, List, ListItem, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {localDateToUTCTimestamp, utcTimestampToFormattedString} from "../../utility/dateUtil";

interface propsType {
    fromDate: number,
    toDate: number,
}

export default function DayPlanDashBoard(props: propsType) {
    const [dayPlansWithErrors, setDayPlansWithErrors] = useState<DayPlanModel[]>([])
    const availabilityServices = useAvailability(props.fromDate, props.toDate);
    const {t} = useTranslation();
    const generateCheckAvailability = (date: number) => {
        return (id: number) => {
            return availabilityServices.resourceIsAvailable(id, date);
        }
    }
    //initialize the Dashboard Date
    useEffect(() => {
        let initialize = async () => {
            let dayPlans = await getDayPlansForRange(props.fromDate, props.toDate)
            let newDayPlansWithErrors: DayPlanModel[] = []

            for (let dayPlan of dayPlans) {
                const availabilityFunction = generateCheckAvailability(localDateToUTCTimestamp(dayPlan.date))
                let hasError = false;

                for (let team of dayPlan.teams) {
                    let teamValid = validateTeamConfiguration(team, availabilityFunction)
                    if (!teamValid) {
                        hasError = true
                        break;
                    }
                }
                if (hasError)
                    newDayPlansWithErrors.push(dayPlan)
            }
            setDayPlansWithErrors(newDayPlansWithErrors)
        }
        initialize();
    }, [props.toDate, props.fromDate])

    return <Box>
        <Typography variant={"h6"} align={"center"}>{t("dashboard.faultyDayPlans")}</Typography>
        <List>
            {dayPlansWithErrors.map((dayPlan, index) => {
                return <ListItem key={index}>
                    {utcTimestampToFormattedString(dayPlan.date)}
                </ListItem>
            })
            }
        </List>
    </Box>
}
