import TeamModel from "../../../../model/types/TeamModel";
import {Document, Page, pdf, StyleSheet, Text, View} from "@react-pdf/renderer";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {utcTimestampToFormattedString} from "../../../../utility/dateUtil";
import constructionSiteModel from "../../../../model/types/basistypes/ressources/ConstructionSiteModel";
import {resolveTeamView} from "../../../../model/ModelController/Planning/DayPlanControllerAdapter";
import {getDayPlansForDay} from "../../../../model/ModelController/Planning/DayPlanControllerV2";
import {CircularProgress, Dialog, DialogContent} from "@mui/material";
import {saveAs} from 'file-saver';

interface propsType {
	date: number;
	open: boolean;
	handleClose: () => void;
	team?: TeamModel;
}

export default function TeamWorkPlanPDF(props: propsType) {
	const {t} = useTranslation();

	const styles = StyleSheet.create({
		page: {
			flexDirection: "column",
			paddingTop: 35,
			paddingBottom: 65,
			paddingHorizontal: 35,
		},
		sectionWrapper: {
			alignItems: "flex-start",
			marginTop: 5,
			marginRight: 10,
			marginLeft: 10,
			marginBottom: 10,
			padding: 5,
		},
		sectionHeader: {
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			paddingBottom: 10,
			borderBottom: "1px solid grey",
		},
		section: {
			width: "90%",
			alignItems: "flex-start",
		},
		footer: {
			position: "absolute",
			bottom: 30,
			marginLeft: 35,
			marginRight: 10,
			width: "100%",
			justifyContent: "center",
			alignItems: "center",
		},
		pageNum: {
			position: "absolute",
			bottom: 20,
			marginLeft: 35,
			marginRight: 10,
			width: "100%",
			justifyContent: "flex-end",
			alignItems: "flex-end",
		},
	});

	useEffect(() => {
		if (props.team) {
			generatePdfForSingleTeam();
		} else {
			generatePdfForDayplan();
		}
	}, [props.date, props.team])

	const generatePdfForSingleTeam = (): void => {
		const team = props.team;
		if (team) {
			const teamId = team.id;
			resolveTeamView(props.date, teamId, team)
				.then(team => {
					const teamName = team.name ? team.name : "Team_" + teamId;
					generatePdfForTeams([team], t("teamWorkPlanPDF.fileName") + teamName + "_" + utcTimestampToFormattedString(props.date))
				})
				.finally(props.handleClose)
		}
	}

	const generatePdfForDayplan = (): void => {
		getDayPlansForDay(props.date).then(dayPlans => {
			if (dayPlans.length === 1) {
				const teams = dayPlans[0].teams.map((team, index) => {
					return resolveTeamView(props.date, team.id, team);
				})
				Promise.all(teams)
					.then(teams => generatePdfForTeams(teams, t("teamWorkPlanPDF.fileName") + utcTimestampToFormattedString(props.date)))
					.finally(props.handleClose)
			}
		})
	}

	const generatePdfForTeams = (teams: TeamModel[], title: string) => {
		pdf(
			<Document title={title}>
				{teams.map((team: TeamModel) => {
					return (
						<Page size="A4" style={styles.page} wrap>
							<View fixed style={{...styles.sectionWrapper}}>
								<View style={{...styles.sectionHeader}}>
									<Text
										style={{fontSize: "20pt"}}>{team?.name || t("team.title") + " " + team.id + " "} </Text>
									<Text>{t("teamWorkPlanPDF.workAssignment") + " " + utcTimestampToFormattedString(props.date)} </Text>
								</View>
								<View style={{...styles.sectionWrapper}}>
									<View style={{...styles.section}}>
										<Text
											style={{fontSize: "20pt"}}>{t("foreman.title") + ": " + getForeman(team)}</Text>
									</View>
								</View>
								<View
									style={{
										...styles.sectionWrapper,
										flexDirection: "row",
										width: "90%"
									}}>
									<View style={{...styles.section, width: "50%"}}>
										<Text style={{fontSize: "20pt"}}>{t("employee.title")}</Text>
										{team.employees.map((employee, index) => {
											return (
												<Text style={{fontSize: "14pt"}}>
													{" "}
													{t("employee.title")} {index + 1} : {employee.surname + ", " + employee.name}{" "}
												</Text>
											);
										})}
									</View>
									<View style={{...styles.section, width: "50%"}}>
										<Text style={{fontSize: "20pt"}}>{t("vehicle.title")}</Text>
										{team.vehicles.map((vehicle, index) => {
											return (
												<Text style={{fontSize: "14pt"}}>
													{t("vehicle.title")} {index + 1} : {vehicle.licensePlace}{" "}
												</Text>
											);
										})}
									</View>
								</View>
							</View>
							<View style={{...styles.sectionWrapper}} wrap>
								<View fixed style={{...styles.sectionHeader, marginTop: 10}}>
									<Text
										style={{fontSize: "20pt"}}>{t("constructionSite.titlePlural")}</Text>
								</View>
								{team.constructionSites.map((constructionSite, index) => {
									return (
										<View
											wrap={false}
											style={{
												...styles.sectionWrapper,
												margin: 0,
												width: "100%",
												flexDirection: "row",
												borderBottom: "1px solid grey",
											}}
										>
											<View style={{...styles.section, alignItems: "flex-start"}}>
												<Text style={{fontSize: "14pt"}}>
													{" "}
													{t("constructionSite.title")} {index + 1}:
												</Text>
												<Text
													style={{fontSize: "14pt"}}> {constructionSite.title}</Text>
												<Text
													style={{fontSize: "10pt"}}> {t("customer.customer-contact") + ":"}</Text>
												{constructionSite?.customerContact?.name && (
													<>
														<Text
															style={{fontSize: "10pt"}}> {constructionSite?.customerContact?.name} </Text>
														<Text
															style={{fontSize: "10pt"}}> {constructionSite?.customerContact?.position || "-"} </Text>
														<Text
															style={{fontSize: "10pt"}}> {constructionSite?.customerContact?.email || "-"} </Text>
														<Text
															style={{fontSize: "10pt"}}> {constructionSite?.customerContact?.mobile || "-"} </Text>
														<Text
															style={{fontSize: "10pt"}}> {constructionSite?.customerContact?.telNr || "-"} </Text>
													</>
												)}
											</View>
											<View
												style={{
													...styles.section,
													alignItems: "flex-start",
													flexDirection: "column",
												}}
											>
												<Text
													style={{fontSize: "14pt"}}>{t("constructionSiteManager")} :</Text>
												<Text
													style={{fontSize: "14pt"}}>{getConstructionManager(constructionSite)}</Text>
											</View>
											<View style={{...styles.section}}>
												{team.tasks
													.filter((task, index, array) => {
														return task.constructionSite.id === constructionSite.id;
													})
													.map((task, index) => {
														return (
															<View
																style={{
																	flexDirection: "column",
																	margin: 1,
																	padding: 1,
																}}
															>
																<Text style={{fontSize: "14pt"}}>
																	{" "}
																	{t("task.title")} {index + 1}:
																</Text>
																<Text
																	style={{fontSize: "14pt"}}> {task.title}</Text>
																<Text
																	style={{fontSize: "12pt"}}> {task.description}</Text>
															</View>
														);
													})}
											</View>
										</View>
									);
								})}
							</View>
							<View fixed style={{...styles.footer}}>
								<Text style={{fontSize: "14pt"}}>{t("teamWorkPlanPDF.warning")}</Text>
							</View>
							<View fixed style={{...styles.pageNum}}>
								<Text style={{fontSize: "10pt"}}
									  render={({
												   pageNumber,
												   totalPages
											   }) => `Seite ${pageNumber} / ${totalPages}`}/>
							</View>
						</Page>
					);
				})}
			</Document>
		).toBlob()
			.then(blob => saveAs(blob, title + ".pdf"))
			.finally(() => {
				props.handleClose();
			})
	}

	function getConstructionManager(constructionSite: constructionSiteModel): string {
		let constructionManagers: string;
		constructionManagers = "";
		constructionSite.constructionManagers?.map((constructionManager) => (constructionManagers += constructionManager.surname + ", " + constructionManager.name + "\n"));

		if (constructionManagers.length > 0) {
			return constructionManagers;
		}

		return t("teamWorkPlanPDF.noConstructionManagerSet");
	}

	function getForeman(team: TeamModel): string {
		if (team.foremans.length > 0) {
			return team.foremans[0].surname + ", " + team.foremans[0].name;
		}
		return t("teamWorkPlanPDF.noForemanSet");
	}

	return (
		<Dialog open={props.open}>
			<DialogContent>
				<CircularProgress/>
			</DialogContent>
		</Dialog>
	)
}
