import DayPlanModel from "../../types/DayPlanModel";
import {
    addDayPlan as addDayPlanV1,
    copyDayPlanToDate as copyDayPlanToDateV1,
    deleteDayPlan as deleteDayPlanV1,
    getDayPlansForDay as getDayPlansForDayV1,
    getDayPlansForRange as getDayPlansForRangeV1
} from "./DayPlanController";
import {
    addDayPlan as addDayPlanV2,
    copyDayPlanToDate as copyDayPlanToDateV2,
    deleteDayPlan as deleteDayPlanV2,
    getDayPlansForDay as getDayPlansForDayV2,
    getDayPlansForRange as getDayPlansForRangeV2,
    resolveTeamView as resolveTeamViewV2
} from "./DayPlanControllerV2"
import TeamModel from "../../types/TeamModel";

const BACKEND_VERSION: number = 2;

export const addDayPlan = async (dayPlan: DayPlanModel) => {
    switch (BACKEND_VERSION) {
        case 2:
            return await addDayPlanV2(dayPlan);
        default:
            return await addDayPlanV1(dayPlan);
    }
}

export const deleteDayPlan = async (dayPlan: DayPlanModel) => {
    switch (BACKEND_VERSION) {
        case 2:
            return await deleteDayPlanV2(dayPlan);
        default:
            return await deleteDayPlanV1(dayPlan)
    }

}

export const copyDayPlanToDate = async (dayPlan: DayPlanModel, targetDate: number): Promise<DayPlanModel> => {
    switch (BACKEND_VERSION) {
        case 2:
            return await copyDayPlanToDateV2(dayPlan, targetDate)
        default:
            return await copyDayPlanToDateV1(dayPlan, targetDate)
    }
}

export const getDayPlansForRange = async (dateFrom: number, dateTo: number): Promise<DayPlanModel[]> => {


    try {
        switch (BACKEND_VERSION) {
            case 2:
                return await getDayPlansForRangeV2(dateFrom, dateTo)
            default:
                return await getDayPlansForRangeV1(dateFrom, dateTo)
        }
    } catch (e) {
        console.error(e)
        debugger;
        throw  e
    }
}

export const resolveTeamView = async (dayPlanDate: number, teamId: number, team: TeamModel): Promise<TeamModel> => {

    switch (BACKEND_VERSION) {
        case 2:
            return await resolveTeamViewV2(dayPlanDate, teamId)
        default:
            return team

    }


}

export const getDayPlansForDay = async (date: number): Promise<DayPlanModel[]> => {
    switch (BACKEND_VERSION) {
        case 2:
            return await getDayPlansForDayV2(date)
        default:
            return await getDayPlansForDayV1(date)
    }
}
