import EmployeeModel from "../model/types/basistypes/ressources/EmployeeModel";
import VehicleModel from "../model/types/basistypes/ressources/VehicleModel";
import EquipmentModel from "../model/types/basistypes/ressources/EquipmentModel";
import {isEmployeeModel, isVehicleModel} from "./typescriptUtil";

export function getIdentifier(resource: EmployeeModel | VehicleModel | EquipmentModel): string {
    if (isEmployeeModel(resource)) {
        return `${resource.surname}, ${resource.name}`
    } else if (isVehicleModel(resource)) {
        return `${resource.licensePlace}`
    } else {
        return `${resource.producer}[${resource.type}]`
    }
}

export function containsFilterValue(object: any, filtervalue: string): boolean {
    let copy = {...object};
    //Remove all unwanted properties
    delete copy.id;
    const objectAsString = JSON.stringify(copy);
    return objectAsString.toLowerCase().includes(filtervalue.toLowerCase())
}

