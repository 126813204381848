import React, {ReactNode, useEffect, useState} from "react";
import {Add, FilterAlt, FilterAltOff} from "@mui/icons-material";
import {Divider, Grid, ListItemButton, ListItemText,} from "@mui/material";
import {ResourceStyledFab, ResourceStyledPickList} from "../../../StyledComponents/ComponentStyled/ResourceStyled";
import {GlobalStyledPaper} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import FilterPopover from "./FilterPopover";
import DeepEquipmentListItem from "../Equipment/DeepEquipmentListItem";
import EquipmentModel from "../../../../model/types/basistypes/ressources/EquipmentModel";
import ResourceListSkeleton from "./ResourceListSkeleton";
import {containsFilterValue} from "../../../../utility/util";

interface propsType {
    resources: any[],
    getListItemText: (resource: any) => ReactNode,
    resourceClickCallBack: (resource: any, index: number) => void,
    resourcesLoaded?: boolean
    addItemCallback?: () => void,
    selectedItem: number,
    initialFilterValue?: string,
    isEquipment?: boolean
}

export default function ResourceList(props: propsType) {

    const [anchorEl, setAnchor] = useState<Element | null>(null);
    const [filterPopover, setFilterPopover] = useState({
        popover: false,
        resourceFilterValue: props.initialFilterValue ? props.initialFilterValue : ""
    });


    useEffect(() => {
        setFilterPopover({
            popover: false,
            resourceFilterValue: props.initialFilterValue ? props.initialFilterValue : ""
        })
    }, [props.initialFilterValue])


    return (
        <GlobalStyledPaper>
            <Grid container xs={12}
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
            >

                <Grid item container xs={12} justifyContent={"center"}>
                    {(props.addItemCallback) ?
                        <Grid item xs={2}>
                            <ResourceStyledFab color={"secondary"} onClick={props.addItemCallback} size={"small"}>
                                <Add/>
                            </ResourceStyledFab>
                        </Grid> : <div/>}
                    <Grid item xs={2}>
                        <ResourceStyledFab color={"secondary"} size={"small"} onClick={(event) => {
                            setFilterPopover({...filterPopover, popover: true})
                            setAnchor(event.currentTarget)
                        }}>
                            {
                                ((filterPopover.resourceFilterValue) ? (<FilterAlt/>) : (
                                    <FilterAltOff/>))
                            }
                        </ResourceStyledFab>
                            <FilterPopover open={filterPopover.popover}
                                           anchor={anchorEl}
                                           value={props.initialFilterValue}
                                           filterValueCallback={value => setFilterPopover({
                                               ...filterPopover,
                                               resourceFilterValue: value
                                           })}
                                           onCloseCallback={() => {
                                               setFilterPopover({...filterPopover, popover: false})
                                           }}/>
                    </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent={"center"}>
                    {(props.resourcesLoaded !== undefined && !props.resourcesLoaded) ?
                        <ResourceListSkeleton times={20}/> : <ResourceStyledPickList>
                            {(!props.isEquipment) ? <Divider/> : <div/>}
                            {
                                props.resources?.map((resource, index) => {
                                    if (!containsFilterValue(resource, filterPopover.resourceFilterValue)) {
                                        return null;
                                    }
                                    return <>
                                        {(props.isEquipment) ? <DeepEquipmentListItem
                                                equipment={resource}
                                                selected={props.selectedItem}
                                                    setSelected={(equipment: EquipmentModel) => {
                                                        props.resourceClickCallBack(equipment, index)
                                                    }}
                                                    getListItemText={props.getListItemText}/> :

                                                <ListItemButton key={index}
                                                                selected={(index === props.selectedItem)}
                                                                onClick={() => {
                                                                    props.resourceClickCallBack(resource, index)
                                                                }}
                                                                sx={{"&.Mui-selected:hover": {backgroundColor: "action.hover",},}}>
                                                    <ListItemText
                                                        primaryTypographyProps={{
                                                            color: (index === props.selectedItem) ? "text.secondary" : "text.primary"
                                                        }}>
                                                        {props.getListItemText(resource)}
                                                    </ListItemText>
                                                </ListItemButton>

                                            }
                                            {(!props.isEquipment) ? <Divider/> : <div/>}
                                        </>

                                    }
                                )
                            }
                        </ResourceStyledPickList>}
                </Grid>
            </Grid>
        </GlobalStyledPaper>
    )
}
