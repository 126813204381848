import firebase from "firebase";
import firebaseConfig from "./FireBaseConfig";
import Firestore = firebase.firestore.Firestore;
import Query = firebase.firestore.Query;
import DocumentData = firebase.firestore.DocumentData;
import CollectionReference = firebase.firestore.CollectionReference;

require("firebase/firestore");

let isInitialized: boolean = false;
let secondaryInitialzed: boolean = false;

let primaryApp: firebase.app.App;
let authenticationApp: firebase.app.App;
let dbConnection: firebase.firestore.Firestore;

const getFirebase = () => {
    if (!isInitialized) {
        primaryApp = firebase.initializeApp(firebaseConfig)
        primaryApp.firestore().settings({cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED})
        dbConnection = primaryApp.firestore();
        dbConnection.enablePersistence({synchronizeTabs: true})
        /*keeping this to enable during local testing.
        if (window.location.hostname === "localhost") {
            firebase.functions().useEmulator("localhost", 5001)
        }*/
        isInitialized = true;
    }
    return primaryApp;
}

const getUserCreationFirebaseConnection = () => {
    if (!secondaryInitialzed) {
        authenticationApp = firebase.initializeApp(firebaseConfig, "UserCreationApp")
        secondaryInitialzed = true;
    }
    return authenticationApp;

}

async function deleteCollection(db: Firestore, collectionRef: CollectionReference, batchSize: number) {

    const query = collectionRef.orderBy('__name__').limit(batchSize);

    return new Promise((resolve, reject) => {
        deleteQueryBatch(db, query, resolve).catch(reject);
    });
}

async function deleteQueryBatch(db: Firestore, query: Query<DocumentData>, resolve: (value?: unknown) => void) {
    const snapshot = await query.get();

    const batchSize = snapshot.size;
    if (batchSize === 0) {
        // When there are no documents left, we are done
        resolve();
        return;
    }

    // Delete documents in a batch
    const batch = db.batch();
    snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
    });
    await batch.commit();

    // Recurse on the next process tick, to avoid
    // exploding the stack.
    process.nextTick(() => {
        deleteQueryBatch(db, query, resolve);
    });
}

const isAuthenticated = () => {
    const initialFirebase = getFirebase();
    let auth = initialFirebase.auth()
    let user = auth.currentUser
    return user != null;
}

const getUser = () => {
    const initialFirebase = getFirebase();
    let auth = initialFirebase.auth()
    return auth.currentUser;
}


const getFireStore = () => {
    if (dbConnection) {
        return dbConnection;
    } else {
        let firebase = getFirebase();
        return firebase.firestore();
    }


}

export {getFirebase, isAuthenticated, getUserCreationFirebaseConnection, getUser, getFireStore, deleteCollection}



