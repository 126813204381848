import {AppBar, Box, Grid, IconButton, Toolbar, Typography} from "@mui/material";
import {ArrowBackIosNew, Delete, Print, Save} from "@mui/icons-material";
import React from "react";
import useTheme from "@mui/material/styles/useTheme";

interface propsType {
    iconBackAction: () => void,
    iconSaveAction?: () => void,
    iconPrintAction?: () => void,
    iconDeleteAction?: () => void,
    title: string,
}

export default function FullScreenDialogSidebar(props: propsType) {
    const theme = useTheme();
    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position="sticky">
                <Toolbar disableGutters>
                    <Grid item xs={12} container alignItems={"center"} justifyContent={"center"} sx={{height: "100%"}}>
                        <Grid item xs={2} container alignItems={"center"} justifyContent={"center"}>
                            {
                                (props.iconBackAction) ?
                                    <Grid item>
                                        <IconButton>
                                            <ArrowBackIosNew fontSize={"large"} onClick={props.iconBackAction}/>
                                        </IconButton>
                                    </Grid> : <div/>
                            }
                            {
                                (props.iconSaveAction) ?
                                    <Grid item>
                                        <IconButton>
                                            <Save fontSize={"large"} onClick={props.iconSaveAction}/>
                                        </IconButton>
                                    </Grid> : <div/>
                            }
                            {
                                (props.iconPrintAction) ?
                                    <Grid item>
                                        <IconButton>
                                            <Print fontSize={"large"}
                                                   sx={{color: `${theme.palette.secondary.main}`}}
                                                   onClick={props.iconPrintAction}>
                                            </Print>
                                        </IconButton>
                                    </Grid> : <div/>
                            }
                            {
                                (props.iconDeleteAction) ?
                                    <Grid item>
                                        <IconButton>
                                            <Delete fontSize={"large"} onClick={props.iconDeleteAction}/>
                                        </IconButton>
                                    </Grid> : <div/>
                            }
                        </Grid>
                        <Grid item container xs={10} justifyContent={"center"}>
                            <Typography variant={"h4"} align={"center"}>
                                {props.title}
                            </Typography>
                        </Grid>

                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    )
};
