import React, {useContext, useEffect, useState} from "react";
import DayPlanModel from "../../model/types/DayPlanModel";
import {AppBarInfoAreaContext} from "../Sidebar/AppBarInfoAreaContext";
import {
	Avatar,
	Grid,
	List,
	ListItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getDayPlansForDay} from "../../model/ModelController/Planning/DayPlanControllerAdapter";
import HouseIcon from "@mui/icons-material/House";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ConstructionIcon from "@mui/icons-material/Construction";
import {RouteComponentProps} from "react-router-dom";
import TeamModel from "../../model/types/TeamModel";
import {GlobalStyledTextField} from "../StyledComponents/GlobalStyled/GlobalStyled";
import {localDateToUTCTimestamp, utcTimestampToFormattedString} from "../../utility/dateUtil";
import sliceTeams from "./Helper/DayViewTeamSlicer";

type TParams = { date: string };

function DayPlanView(props: RouteComponentProps<TParams>) {
	const [localDayPlan, setLocalDayPlan] = useState<DayPlanModel>({
		teams: [],
		date: localDateToUTCTimestamp(props.match.params.date),
	});

	const [dayPlanTeamSlice, setDayPlanTeamSlice] = useState<TeamModel[]>([]);
	const [intervalCount, setIntervalCount] = useState<number>(0);
	const [numberOfResourcesToDisplay, setNumberOfResourcesToDisplay] = useState<number>(15);
	const [secondsUntilNextSlide, setSecondsUntilNextSlide] = useState<number>(10);
	const [teamSlices, setTeamSlices] = useState<Map<number, TeamModel[]>>(new Map<number, TeamModel[]>());
	const { t } = useTranslation();
	const appbarContext = useContext(AppBarInfoAreaContext);

	useEffect(() => {
		appbarContext.updateContent(t("dayPlan.manageTitle") + " " + utcTimestampToFormattedString(props.match.params.date));
		getDayPlansForDay(localDayPlan.date).then((dayPlans: DayPlanModel[]) => {
			if (dayPlans?.length > 0) {
				setLocalDayPlan(dayPlans[0]);
			} else {
				return;
			}
		});
	}, [appbarContext, localDayPlan.date, props.match.params.date, t]);

	useEffect(() => {
		let newTeamSlices = sliceTeams(localDayPlan.teams, numberOfResourcesToDisplay);
		setTeamSlices(newTeamSlices);
	}, [numberOfResourcesToDisplay, localDayPlan.teams]);

	useEffect(() => {
		setIntervalCount(0);

		const interval = setInterval(() => {
			setIntervalCount((time) => (time + 1 >= teamSlices.size ? 0 : time + 1));
		}, secondsUntilNextSlide * 1000);
		return () => clearInterval(interval);
	}, [teamSlices.size, numberOfResourcesToDisplay, secondsUntilNextSlide]);

	useEffect(() => {
		const slice = teamSlices.get(intervalCount);
		if (slice) setDayPlanTeamSlice(slice);
	}, [localDayPlan.teams, numberOfResourcesToDisplay, intervalCount, teamSlices]);

	const renderEntry = (title: string, avatar: any = null, index = 0) => {
		return (
			<ListItem key={index} sx={{ width: "100%", p: 1 / 2 }}>
				<Paper elevation={2} sx={{ backgroundColor: "paper.dark", width: "100%" }}>
					<Grid container justifyContent={"flex-start"} alignItems={"center"}>
						<Grid item xs={3}>
							{avatar}
						</Grid>
						<Grid item xs={9} sx={{ pl: 1 / 4 }}>
							<Typography variant={"body2"}> {title}</Typography>
						</Grid>
					</Grid>
				</Paper>
			</ListItem>
		);
	};
	const columnTitles: string[] = [
		t("team.title"),
		t("constructionSiteManager"),
		t("foreman.title"),
		t("employee.titlePlural"),
		t("constructionSite.title"),
		t("vehicle.titlePlural"),
		t("equipment.titlePlural"),
	];
	return (
		<Grid container xs={12}>
			{/* Container To Render Teams */}
			<Paper sx={{ width: "100%", overflow: "hidden" }}>
				<TableContainer sx={{ width: "100%" }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow sx={{ width: "100%" }}>
								<TableCell align={"center"} sx={{ p: 1 / 4 }}>
									<GlobalStyledTextField
										label={t("dayPlan.numberOfResourcesToBeDisplayed")}
										type={"number"}
										InputProps={{ inputProps: { min: 1 } }}
										value={numberOfResourcesToDisplay}
										onChange={(event) => setNumberOfResourcesToDisplay(Number.parseInt(event.target.value))}
									/>
								</TableCell>
								<TableCell align={"center"}>
									<GlobalStyledTextField
										label={t("dayPlan.secondsUntilNextSlide")}
										type={"number"}
										InputProps={{ inputProps: { min: 1 } }}
										value={secondsUntilNextSlide}
										onChange={(event) => setSecondsUntilNextSlide(Number.parseInt(event.target.value))}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								{columnTitles.map((title) => (
									<TableCell align={"center"} sx={{ maxWidth: "15%" }}>
										{title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{dayPlanTeamSlice.map((team, index) => {
								return (
									<TableRow>
										<TableCell sx={{ minWidth: "10%", width: "10%", maxWidth: "10%" }} align={"center"} padding={"none"}>
											{renderEntry(
												team?.name || t("team.title") + " " + team.id,
												<Avatar
													sx={{
														backgroundColor: "secondary.main",
														height: 30,
														width: 30,
														my: 1 / 2,
														ml: 1 / 2,
														mr: 1,
													}}
												>
													{team.id}
												</Avatar>
											)}
										</TableCell>
										<TableCell sx={{ minWidth: "15%", width: "15%", maxWidth: "15%" }} align={"center"} padding={"none"}>
											<List>
												{team.constructionSites.map((constructionSite) =>
													constructionSite.constructionManagers.map((constructionSiteManager, index) => {
														return renderEntry(
															constructionSiteManager.surname + ", " + constructionSiteManager.name,
															<Avatar
																sx={{
																	backgroundColor: "secondary.main",
																	height: 30,
																	width: 30,
																	m: 1 / 2,
																}}
															/>,
															index
														);
													})
												)}
											</List>
										</TableCell>

										<TableCell sx={{ minWidth: "15%", width: "15%", maxWidth: "15%" }} align={"center"} padding={"none"}>
											<List>
												{team.foremans.map((foreman, index) => {
													return renderEntry(
														foreman.surname + ", " + foreman.name,
														<Avatar
															sx={{
																backgroundColor: "secondary.main",
																height: 30,
																width: 30,
																m: 1 / 2,
															}}
														/>,
														index
													);
												})}
											</List>
										</TableCell>

										<TableCell sx={{ minWidth: "15%", width: "15%", maxWidth: "15%" }} align={"center"} padding={"none"}>
											<List>
												{team.employees.map((employee, index) => {
													return renderEntry(
														employee.surname + ", " + employee.name,
														<Avatar
															sx={{
																backgroundColor: "secondary.main",
																height: 30,
																width: 30,
																m: 1 / 2,
															}}
														/>,
														index
													);
												})}
											</List>
										</TableCell>
										<TableCell sx={{ minWidth: "15%", width: "15%", maxWidth: "15%" }} align={"center"} padding={"none"}>
											<List>
												{team.constructionSites.map((constructionSite, index) => {
													return renderEntry(
														constructionSite.title,
														<Avatar
															sx={{
																backgroundColor: "secondary.main",
																height: 30,
																width: 30,
																m: 1 / 2,
															}}
														>
															<HouseIcon />
														</Avatar>,
														index
													);
												})}
											</List>
										</TableCell>
										<TableCell sx={{ minWidth: "15%", width: "15%", maxWidth: "15%" }} align={"center"} padding={"none"}>
											<List>
												{team.vehicles.map((vehicle, index) => {
													return renderEntry(
														vehicle.licensePlace,
														<Avatar
															sx={{
																backgroundColor: "secondary.main",
																height: 30,
																width: 30,
																m: 1 / 2,
															}}
														>
															<LocalShippingIcon />
														</Avatar>,
														index
													);
												})}
											</List>
										</TableCell>
										<TableCell sx={{ minWidth: "15%", width: "15%", maxWidth: "15%" }} align={"center"} padding={"none"}>
											<List>
												{team.equipment.map((machine, index) => {
													return renderEntry(
														machine.producer + "[ " + machine.type + " ]",
														<Avatar
															sx={{
																backgroundColor: "secondary.main",
																height: 30,
																width: 30,
																m: 1 / 2,
															}}
														>
															<ConstructionIcon />
														</Avatar>,
														index
													);
												})}
											</List>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Grid>
	);
}

export default DayPlanView;
