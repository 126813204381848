import React, {useEffect, useState} from "react";
import {getEmployees} from "../../model/ModelController/Resources/EmployeeController";
import {getVehicle} from "../../model/ModelController/Resources/VehicleController";
import {getEquipment} from "../../model/ModelController/Resources/EquipmentController";
import {
    getAppointmentsForRange,
    getEventsThatAreBlockedAndNotUsableAgain
} from "../../model/ModelController/times/AppointmentController";
import {AppointmentModel, ResourceType} from "../../model/types/basistypes/times/AppointmentModel";
import ResponsiveLineChart, {LineDiagramData} from "./Helper/ResponsiveLineChart";
import {addDays, subDays} from "date-fns";
import {useTranslation} from "react-i18next";
import {
    getCurrentDateAtStartAsUTCTimestamp,
    localDateToUTCTimestamp,
    localDateToUTCTimestampEnd,
    utcTimestampToFormattedISOString
} from "../../utility/dateUtil";
import EmployeeModel from "../../model/types/basistypes/ressources/EmployeeModel";
import VehicleModel from "../../model/types/basistypes/ressources/VehicleModel";
import EquipmentModel from "../../model/types/basistypes/ressources/EquipmentModel";


function AvailabilityOverTime() {
    const [data, setData] = useState<LineDiagramData[]>([])
    const {t, i18n} = useTranslation();


    useEffect(() => {
        const fetchData = async () => {
            let promises: Promise<EmployeeModel[] | VehicleModel[] | EquipmentModel[] | AppointmentModel[]>[] = [];
            promises.push(getEmployees());//index 0
            promises.push(getVehicle());//index 1
            promises.push(getEquipment());//index 2

            let fromDate = getCurrentDateAtStartAsUTCTimestamp()
            let startDate = localDateToUTCTimestamp(subDays(fromDate, 15));
            let endDate = localDateToUTCTimestampEnd(addDays(fromDate, 30));


            promises.push(getAppointmentsForRange(ResourceType.EMPLOYEE, startDate, endDate)); //index 3
            promises.push(getAppointmentsForRange(ResourceType.VEHICLE, startDate, endDate)); //index 4
            promises.push(getAppointmentsForRange(ResourceType.EQUIPMENT, fromDate, endDate)); //index 5
            promises.push(getEventsThatAreBlockedAndNotUsableAgain(endDate)) //index 6

            let allResolvedPromises = await Promise.all(promises);
            let employees: EmployeeModel[] = allResolvedPromises[0] as EmployeeModel[];
            let vehicles: VehicleModel[] = allResolvedPromises[1] as VehicleModel[];
            let machines: EquipmentModel[] = allResolvedPromises[2] as EquipmentModel[];
            let events: AppointmentModel[] = [];

            events = events.concat(
                allResolvedPromises[3] as AppointmentModel[],
                allResolvedPromises[4] as AppointmentModel[],
                allResolvedPromises[5] as AppointmentModel[],
                allResolvedPromises[6] as AppointmentModel[]);

            let newEventMap = new Map<string, Map<number, AppointmentModel[]>>();
            events.forEach((localEvent: AppointmentModel, index) => {
                let mapForDay = newEventMap.get(utcTimestampToFormattedISOString(localEvent.date))
                if (!mapForDay) {
                    mapForDay = new Map<number, AppointmentModel[]>();
                }
                //first check whether there already is another event for that resource
                let currentEntry = mapForDay.get(localEvent.resource.id);

                if (currentEntry) {
                    mapForDay.set(localEvent.resource.id, [...currentEntry, localEvent])
                } else {
                    mapForDay.set(localEvent.resource.id, [localEvent]);
                }

                newEventMap.set(utcTimestampToFormattedISOString(localEvent.date), mapForDay);
            })

            let newDiagramData: LineDiagramData[] = [];

            while (startDate < endDate) {
                let localDate = utcTimestampToFormattedISOString(startDate);
                let map = newEventMap.get(localDate);

                let employeeAvailable = allResolvedPromises[0]
                let vehicleAvailable = allResolvedPromises[1]
                let machineAvailable = allResolvedPromises[2]
                if (map) {
                    const getEventForResource = (Id: number) => {
                        if (map) {
                            let events = map.get(Id);
                            return (events) ? events : []
                        } else {
                            return []
                        }
                    }

                    const resourceIsAvailable = (Id: number) => {
                        let events = getEventForResource(Id);
                        //if no entry is found that blocks the Resource
                        //the loop won't terminate and we return true
                        for (let eventEntry of events) {
                            if (!eventEntry.usableOn || (!eventEntry.usableAfter.usable && !eventEntry.usableAfter.reusable)) {
                                return false;
                            }
                        }
                        return true;
                    }

                    employeeAvailable = employees.filter((employee) => {
                        return resourceIsAvailable(employee.id)
                    })
                    vehicleAvailable = vehicles.filter((vehicles) => {
                        return resourceIsAvailable(vehicles.id)
                    })
                    machineAvailable = machines.filter((machine) => {
                        return resourceIsAvailable(machine.id)
                    })
                }
                newDiagramData.push({
                    name: localDate,
                    employee: employeeAvailable.length,
                    vehicle: vehicleAvailable.length,
                    equipment: machineAvailable.length
                })
                startDate = addDays(startDate, 1).getTime();
            }
            setData(newDiagramData)
        }
        fetchData()
    }, [])

    return (
        <ResponsiveLineChart title={t("dashboard.availabilityOverTime")} data={data}/>
    )
}

export default AvailabilityOverTime;
