import React from "react";
import {Skeleton,} from "@mui/material";
import {ResourceStyledPickList} from "../../../StyledComponents/ComponentStyled/ResourceStyled";
import useTheme from "@mui/material/styles/useTheme";

interface ResourceListSkeletonProps {
    times: number;
}

export default function ResourceListSkeleton(props: ResourceListSkeletonProps) {
    const theme = useTheme();
    return (
        <ResourceStyledPickList>
            {
                [...Array(props.times)]
                    .map(() => {
                            return (
                                <Skeleton
                                    width={"100%"}
                                    height={"4vh"}
                                    sx={{bgcolor: theme.palette.primary.main}}/>
                            )
                        }
                    )
            }
        </ResourceStyledPickList>
    )
}
