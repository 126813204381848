import React, {ReactNode, useState} from "react";
import DraggableJSXwrapper from "./DraggableJSXwrapper";
import useTheme from "@mui/material/styles/useTheme";
import {
    Avatar,
    Grid,
    IconButton,
    List,
    ListItemButton,
    Paper,
    Popover,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
    useMediaQuery
} from "@mui/material";
import {MoreVert,} from "@mui/icons-material";
import {AppointmentModel} from "../../../model/types/basistypes/times/AppointmentModel";
import {utcTimestampToFormattedString} from "../../../utility/dateUtil";
import {useTranslation} from "react-i18next";
import {groupBy} from "../../../utility/typescriptUtil";
import {styled} from "@mui/material/styles";


export interface PopOverActions {
    icon: ReactNode,
    actionText: string,
    action: () => void
}

interface propsType {
    title: string,
    id: number,
    index: number,
    referencedObject: any,
    iconAction?: () => void,
    iconDeleteAction?: () => void,
    actions: PopOverActions[]
    children?: React.ReactNode,
    deactivateDnD?: boolean,
    available: boolean,
    blockingEvents?: Array<AppointmentModel>
}

export default function DnDItemCard(props: propsType) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const available = props.available;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalAnchor, setModalAnchor] = useState<Element | null>(null);
    const {t} = useTranslation();

    const wrapInCloseModal = (action: () => void) => {
        return () => {
            action();
            setModalOpen(false);
        }
    }
    const renderMoreVertIcon = () => {
        const numberOfActions = props.actions.length;

        switch (numberOfActions) {
            case 0:
                return <div/>
            case 1:
                return <Grid item xs={12}>
                    <IconButton disableRipple size={"small"} aria-label="settings" onClick={props.actions[0].action}>
                        {props.actions[0].icon}
                    </IconButton>
                </Grid>
            default:
                return <Grid item xs={12}>
                    <IconButton disableRipple size={"small"} aria-label="settings" onClick={(event) => {
                        setModalOpen(true);
                        setModalAnchor(event.currentTarget)
                    }}>
                        <MoreVert/>
                    </IconButton>
                    <Popover
                        open={modalOpen}
                        anchorEl={modalAnchor}
                        onClose={() => {
                            setModalOpen(false)
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        <List>
                            {
                                props.actions.map(
                                    (item, index) => {
                                        return <ListItemButton onClick={wrapInCloseModal(item.action)} key={index}>
                                            {item.icon}
                                            {item.actionText}

                                        </ListItemButton>
                                    }
                                )
                            }
                        </List>
                    </Popover>
                </Grid>
        }
    }

    let render =
        <Paper sx={(available) ? {m: 1 / 2, backgroundColor: "paper.dark"} : {
            m: 1 / 2,
            background: theme.palette.paper.error
        }}>
            <Grid item xs={12} container justifyContent={"flex-start"} alignItems={"center"}>
                {(matches) ?
                    <Grid item xs={3} container justifyContent={"center"}>
                        <Avatar sx={{backgroundColor: "secondary.main", height: 30, width: 30}}>
                        </Avatar>
                    </Grid> : <div/>
                }
                <Grid item xs={(props.actions.length > 0) ? (matches ? 6 : 9) : (matches ? 9 : 12)} container
                      justifyContent={"flex-start"}>
                    <Typography variant={"body2"}> {props.title}</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent={"flex-start"}>
                    {
                        renderMoreVertIcon()
                    }
                </Grid>
            </Grid>
        </Paper>


    function removeDuplicateEvents(events: Array<AppointmentModel>) {
        return events.filter((value, index, self) =>
            index === self.findIndex((event) => (
                event.id === value.id
            ))
        );
    }

    function getOnlyLastEventForEachSeries(events: Array<AppointmentModel>) {
        let eventsGroupedBySeriesId: Map<number, AppointmentModel[]> = groupBy(events, (event: AppointmentModel) => event.appointmentSeriesId);
        let onlyLastEventPerSeriesId: Map<number, AppointmentModel> = new Map();

        // if more then one entry exists per seriesId, just get start and end event
        // @ts-ignore
        for (let seriesId of eventsGroupedBySeriesId.keys()) {
            let eventsOfSeries: Array<AppointmentModel> | undefined = eventsGroupedBySeriesId.get(seriesId);
            if (eventsOfSeries != null) {
                let eventsSortedByDate: Array<AppointmentModel> = eventsOfSeries.sort((event: AppointmentModel) => event.date);
                onlyLastEventPerSeriesId.set(seriesId, eventsSortedByDate[eventsSortedByDate.length - 1])
            }
        }
        return onlyLastEventPerSeriesId;
    }

    function createTooltipMessages(mapWithOnlyLastEventPerSeries: Map<number, AppointmentModel>) {
        let blockedTooltipMessages: Array<string> = []

        // @ts-ignore
        for (let seriesId of mapWithOnlyLastEventPerSeries.keys()) {
            const event: AppointmentModel | undefined = mapWithOnlyLastEventPerSeries.get(seriesId);
            if (event != null) {
                if ((!event.appointmentType?.usableAfter && !event.reusable)) {
                    blockedTooltipMessages.push(t("dayPlan.resource.notReuseableAfterTooltip",
                        {
                            date: utcTimestampToFormattedString(event.date),
                            cause: event.appointmentType?.description,
                        }))
                } else {
                    blockedTooltipMessages.push(t("dayPlan.resource.notAvailableTooltip",
                        {
                            cause: event.appointmentType?.description,
                        }))
                }
            }
        }
        return blockedTooltipMessages;
    }


    const NoMaxWidthTooltip = styled(({className, ...props}: TooltipProps) => (
        <Tooltip {...props} classes={{popper: className}} children={props.children} placement={"bottom"} arrow/>
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
            fontSize: 15,
        },
    });

    const wrapInTooltip = (children: JSX.Element): JSX.Element => {
        // TODO events should not contain duplicates this is caused by  wait getAppointmentsForRange(ResourceType.VEHICLE, fromDate, toDate))
        //                 .concat(await getAppointmentsForRange(ResourceType.EQUIPMENT, fromDate, toDate))
        //                 .concat(await getEventsThatAreBlockedAndNotUsableAgain(toDate));
        // since it can fetch the same data twice
        let events = props.blockingEvents;

        if (events == null || events.length === 0) {
            return children;
        }

        events = removeDuplicateEvents(events)
        let mapWithOnlyLastEventPerSeries = getOnlyLastEventForEachSeries(events);
        let blockedTooltipMessages = createTooltipMessages(mapWithOnlyLastEventPerSeries);

        return (
            <NoMaxWidthTooltip
                title={<span style={{whiteSpace: 'pre-line'}}>{blockedTooltipMessages.join("\n\n")}</span>}
            >
                {children}
            </NoMaxWidthTooltip>
        )
    }

    return (!props.deactivateDnD) ?
        <
            DraggableJSXwrapper
            Id={props.id}
            index={props.index}> {render}
        </DraggableJSXwrapper> : wrapInTooltip(render)
}


