import {
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Typography
} from "@mui/material";
import {
    GlobalStyledDivider,
    GlobalStyledPaper,
    GlobalStyledTextField
} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useCallback, useEffect, useState} from "react";
import EmployeeModel from "../../../../model/types/basistypes/ressources/EmployeeModel";
import useValidator, {proxiedPropertiesOf} from "../../../ValidatorHook";
import UserModel from "../../../../model/types/permissions/UserModel";
import {useTranslation} from "react-i18next";
import Role from "../../../../model/types/permissions/Role";
import {addUser, deleteUser, updateUser} from "../../../../model/ModelController/Permissions/UserController";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UserValidator from "./UserValidator";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {Save, Visibility, VisibilityOff} from "@mui/icons-material";
import {ResourceStyledFormControlLabel} from "../../../StyledComponents/ComponentStyled/ResourceStyled";
import useTheme from "@mui/material/styles/useTheme";
import {Action} from "../../../ActionBar/GenericActionBarIconButton";
import GenericActionBar from "../../../ActionBar/GenericActionBar";


interface propsType {
    user: UserModel | null;
    employee: EmployeeModel;
    index: number;
    roles: Role[];
    updateUserCallback: (user: UserModel, index: number) => void;
}

const UserItem = (props: propsType | any) => {
    const theme = useTheme();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const fieldDescriptor = proxiedPropertiesOf(props.employee);
    const {t} = useTranslation();
    const [loginName, setLoginName] = useState<string | undefined>(props.user?.loginName);
    const [localRole, setLocalRole] = useState<Role | undefined>(props.user?.role);
    const [localId, setLocalId] = useState<string | undefined>(props.user?.id);
    const [showPassword, setShowPassword] = useState(false);
    const [localPassword, setLocalPassword] = useState<string | undefined>();
    const [actions, setActions] = useState<any[]>([])
    const [localLoginEnabled, setLocalLoginEnabled] = useState<boolean>((props.user?.loginEnabled) ? props.user?.loginEnabled : false);
    const validator = useValidator(UserValidator, {loginName: loginName}, props.user);
    const [hasValidUser, setHasValidUser] = useState<boolean>(false);
    const [canCreateUser, setCanCreateUser] = useState<boolean>(false)
    const {enqueueSnackbar} = useSnackbar();
    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const updateExisitingUser = useCallback(() => {
        if (loginName != null) {
            if (props.user !== null) {
                if (localId && localRole) {
                    debugger;
                    updateUser(localId, localRole, localLoginEnabled)
                        .then(() => {

                            props.updateUserCallback({
                                ...props.user,
                                role: localRole,
                                loginName: loginName,
                                loginEnabled: localLoginEnabled
                            } as UserModel, props.index)
                            showSnackbar("success", t("users.userUpdated", {loginName: loginName}))
                        })
                }
            }
        }
    }, [localId, localRole, localLoginEnabled, loginName])

    useEffect(() => {
        if (props.user?._isNew) {
            setIsDirty(true);
        } else {
            setIsDirty(false);
        }

        setLoginName((props.user?.loginName) ? props.user?.loginName : "");
        if (props?.user?.id) {
            setHasValidUser(true)

        } else {
            setHasValidUser(false)
        }
        setCanCreateUser(false)
        setLocalLoginEnabled((props.user?.loginEnabled) ? props.user?.loginEnabled : false)
        setLocalId(props.user?.id)
        setLocalRole(props.user?.role);
        setLocalPassword("");
    }, [props.user, props])


    const deleteActiveUser = (user: UserModel) => {
        return () => deleteUser(user).then(
            () => {
                showSnackbar("success", t("users.userDeleted", {loginName: user.loginName}))
                setHasValidUser(false);
                setCanCreateUser(false);
                setLocalRole(undefined);
                setLoginName("");
            }
        )
    }

    const createNewUser = ( password: string|undefined) => { 
        if (loginName != null && localRole && password) {
            const newUser: UserModel = {
                employee: props.employee,
                loginEnabled: localLoginEnabled,
                loginName: loginName,
                role: localRole
            }
            addUser(newUser, password).then((id) => {
                props.updateUserCallback(newUser, props.index)
                showSnackbar("success", t("users.userCreated", {loginName: loginName}))
                setHasValidUser(true);
                setCanCreateUser(false);
                setLocalLoginEnabled(false);
                setLocalPassword("")
                if (id) {
                    setLocalId(id)
                }
            })
        }
    }

    useEffect(() => {
        if (!(loginName === "" || validator.containsError("loginName") || !localRole || !localPassword) && !hasValidUser) {
            setCanCreateUser(true)
        } else {
            setCanCreateUser(false)
        }

    }, [loginName, validator, localPassword, hasValidUser, localRole])

    useEffect(() => {
        let localActions: any[] = [];
        if (hasValidUser) {
            localActions = localActions.concat([{
                icon: <Save/>,
                name: 'Save',
                tooltip: t("actions.save"),
                callback: updateExisitingUser,
            } as Action, {
                icon: <DeleteIcon/>, name: 'Delete', tooltip: t('actions.delete') + '', callback: () => {
                    deleteActiveUser(props.user)()
                }
            }

            ])
        }

        if (!hasValidUser) {
            localActions.push(
                {
                    icon: <PersonAddIcon/>,
                    name: 'Activate',
                    tooltip: t('actions.activate') + '',
                    disabled: !canCreateUser,
                    callback: () => {
                        createNewUser(localPassword)
                    }
                } as Action
            )
        }

        setActions(localActions)

    }, [hasValidUser, canCreateUser, props?.user, localPassword, updateExisitingUser])


    const convertRolesToMenuItems = () => {
        return props.roles.map((role: Role) => {
            return <MenuItem
                value={role.id}
                key={role.id}
                onClick={() => setLocalRole(role)
                }
            >{role.id}</MenuItem>
        })
    }


    return (
        <Grid container xs={12}>
            <Grid item container xs={12}>
                <GlobalStyledPaper>
                    <Grid item container xs={12}
                          alignItems={"flex-start"}>
                        <GenericActionBar actions={actions}/>
                        <Grid item xs={12}>
                            <Typography variant={"h6"}
                                        align={"center"}>{`${t("employee.title")}: ${props.employee.surname},
                                ${props.employee.name}`}
                            </Typography>
                            <GlobalStyledDivider/>
                        </Grid>
                        <Grid item xs={6}>
                            <GlobalStyledTextField
                                name={fieldDescriptor.loginName}
                                value={loginName || ""}
                                disabled={hasValidUser}
                                label={t(fieldDescriptor.loginName)}
                                onChange={validator.wrapOnChangeInValidator(event => setLoginName(event.target.value))}
                                error={validator.containsError(fieldDescriptor.loginName)}
                                helperText={validator.getErrorMessage(fieldDescriptor.loginName)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <GlobalStyledTextField
                                name={fieldDescriptor.role}
                                required
                                select
                                label={t(fieldDescriptor.role)}
                                value={(localRole?.id) ? localRole.id : ""}
                                variant={"outlined"}>
                                {convertRolesToMenuItems()}
                            </GlobalStyledTextField>
                        </Grid>
                        {(!hasValidUser) ? <Grid item xs={6}>
                            <FormControl variant="outlined"
                                         sx={{
                                             marginLeft: theme.spacing(1),
                                             width: `calc(100% - 4*${theme.spacing(1)})`,
                                             padding: `calc(1*${theme.spacing(1)})`,
                                         }}
                                         disabled={hasValidUser}>

                                <InputLabel
                                    htmlFor="outlined-adornment-password">{t("users.initialPassword")}</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={(localPassword) ? localPassword : ""}
                                    onChange={(e) => {
                                        setLocalPassword(e.currentTarget.value)
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setShowPassword(!showPassword)
                                                }}
                                                onMouseDown={(event) => {
                                                    event.preventDefault();
                                                }}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={t("users.initialPassword")}
                                />
                            </FormControl>
                        </Grid> : ""}
                        <Grid xs={2}>
                            <ResourceStyledFormControlLabel
                                control={
                                    <Checkbox
                                        checked={(localId) ? localLoginEnabled : false}
                                        onChange={() => {
                                            setLocalLoginEnabled(!localLoginEnabled)
                                        }}
                                        name={"users.loginEnabled"}
                                        disabled={(props.user === null && !localId)}
                                    />
                                }
                                label={t("users.loginEnabled") + ""}
                            />
                        </Grid>
                    </Grid>
                </GlobalStyledPaper>
            </Grid>
        </Grid>
    )
}

export default withSnackbar(UserItem);
