import React, {ReactNode, useEffect, useState} from "react";
import TaskModel from "../../../../model/types/basistypes/ressources/tasks/TaskModel";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Box,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	IconButton,
	List,
	ListItem,
	Popover,
	Typography,
} from "@mui/material";
import DraggableJSXwrapper from "../DraggableJSXwrapper";
import DroppableJSXwrapper from "../DroppableJSXwrapper";
import {DnDItem} from "../DndContextWrapper";
import {ExpandMore} from "@mui/icons-material";
import ConstructionSiteModel from "../../../../model/types/basistypes/ressources/ConstructionSiteModel";
import {useTranslation} from "react-i18next";

interface propsType {
	idDND: string;
	tasks: TaskModel[];
	registerDnDCallback: (key: string, newDnDItem: DnDItem) => void;
	referenceUpdateCallBack: (tasks: TaskModel[]) => void;
}

interface indexTasks {
	task: TaskModel;
	realIndex: number;
}

export default function DraggableTaskListByConstructionSites(props: propsType) {
	const [indexTaskMap, setIndexTaskMap] = useState<Map<number, indexTasks[]>>(new Map<number, indexTasks[]>());
	const [constructionSiteMap, setConstructionSiteMap] = useState<Map<number, ConstructionSiteModel>>(new Map<number, ConstructionSiteModel>());
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);

	const { t } = useTranslation();

	useEffect(() => {
		const newIndexMap = new Map<number, indexTasks[]>();
		const newConstructionSiteMap = new Map<number, ConstructionSiteModel>();
		props.tasks.forEach((value, index) => {
			debugger;
			let site = newIndexMap.get(value.constructionSite.id);
			if (site) {
				newIndexMap.set(value.constructionSite.id, [...site, { task: value, realIndex: index } as indexTasks]);
			} else {
				newIndexMap.set(value.constructionSite.id, [{ task: value, realIndex: index } as indexTasks]);
			}
			newConstructionSiteMap.set(value.constructionSite.id, value.constructionSite);
		});

		setIndexTaskMap(newIndexMap);
		setConstructionSiteMap(newConstructionSiteMap);
	}, [props.tasks]);

	const getHierarchyList = (taskMap: Map<number, indexTasks[]>) => {
		let jsx: ReactNode[] = [];

		taskMap.forEach((taskList: indexTasks[], constructionSiteId: number) => {
			let constructionSite = constructionSiteMap.get(constructionSiteId);
			if (constructionSite)
				jsx.push(
					<ListItem key={constructionSite.id}>
						<Accordion disableGutters>
							<AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header" sx={{ m: 0 }}>
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										setAnchorEl(e.currentTarget);
									}}
								>
									<Popover
										open={anchorEl !== null}
										anchorEl={anchorEl}
										onClose={() => {
											setAnchorEl(null);
										}}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "left",
										}}
									>
										<List>
											<ListItem>
												<Typography> {t("customer.contact.name") + ": " + constructionSite?.customerContact?.name} </Typography>
											</ListItem>
											<ListItem>
												<Typography> {t("customer.contact.position") + ": " + constructionSite?.customerContact?.position} </Typography>
											</ListItem>
											<ListItem>
												<Typography> {t("customer.contact.email") + ": " + constructionSite?.customerContact?.email} </Typography>
											</ListItem>
											<ListItem>
												<Typography> {t("customer.contact.mobile") + ": " + constructionSite?.customerContact?.mobile} </Typography>
											</ListItem>
											<ListItem>
												<Typography> {t("customer.contact.telNr") + ": " + constructionSite?.customerContact?.telNr} </Typography>
											</ListItem>
										</List>
									</Popover>
									<Avatar sx={{ backgroundColor: "secondary.main", height: 30, width: 30 }} />
								</IconButton>
								<Typography>
									{" "}
									{constructionSite.title} {taskList.length > 0 ? " (" + taskList.length + ")" : ""}{" "}
								</Typography>
							</AccordionSummary>
							<AccordionDetails sx={{ m: 0, p: 0 }}>
								<List>
									<Grid container>
										{taskList.map((indexTask: indexTasks) => {
											return (
												<Grid item xs={6}>
													<ListItem key={indexTask.task.id}>
														<DraggableJSXwrapper Id={indexTask.task.id} index={indexTask.realIndex}>
															<Card>
																<Box>
																	<CardContent>
																		<Typography textAlign={"center"} style={indexTask.task.resolved ? { textDecoration: "line-through" } : {}} variant={"subtitle1"}>
																			{indexTask.task.title ? indexTask.task.title : <CircularProgress />}
																		</Typography>
																		<Typography variant={"subtitle2"} textAlign={"center"}>
																			{" "}
																			{indexTask.task.description}
																		</Typography>
																	</CardContent>
																</Box>
															</Card>
														</DraggableJSXwrapper>
													</ListItem>
												</Grid>
											);
										})}
									</Grid>
								</List>
							</AccordionDetails>
						</Accordion>
					</ListItem>
				);
		});

		return jsx;
	};

	return (
		<DroppableJSXwrapper
			Id={props.idDND}
			registerCallBack={props.registerDnDCallback}
			referencedUpdateCallback={props.referenceUpdateCallBack}
			dndType={"Task"}
			referencedDnDArray={props.tasks}
		>
			<List sx={{ minHeight: "20vh" }}>{getHierarchyList(indexTaskMap)}</List>
		</DroppableJSXwrapper>
	);
}
