import {ButtonGroup} from "@mui/material";
import React, {useEffect} from "react";
import GenericActionBarIconButton, {Action} from "./GenericActionBarIconButton";

interface GenericActionBarProps {
    actions: Array<Action>
}


export default function GenericActionBar(props: GenericActionBarProps) {
    const genericActionBarKeyListener = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key !== "Control") {
            const suitableActions = props.actions.filter(value => value.actionKey === event.key);
            if (suitableActions.length === 1) {
                event.preventDefault();
                event.stopPropagation();
                const suitableAction: Action = suitableActions[0];
                if (!suitableAction.disabled) {
                    suitableAction.callback();
                }
            } else {
                console.debug("Multiple actions found for button " + event.key + " check your configs.")
            }
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", genericActionBarKeyListener, false)
        return () => document.removeEventListener("keydown", genericActionBarKeyListener, false);
    })

    return (<ButtonGroup variant={"outlined"} aria-label="outlined primary button group" fullWidth={false}>

        {props.actions.map((action: Action) => (
                    // @ts-ignore
                <GenericActionBarIconButton name={action.name} callback={action.callback} icon={action.icon}
                                            disabled={action.disabled}
                                            actionKey={action.actionKey}
                                            tooltip={action.tooltip}/>
        ))}

    </ButtonGroup>)
}