import React, {useEffect, useState} from 'react';
import {TaskDurationUnit} from "../../../../model/types/basistypes/ressources/tasks/TaskModel";
import ConstructionSiteModel from "../../../../model/types/basistypes/ressources/ConstructionSiteModel";
import {getTaskTemplates} from "../../../../model/ModelController/Resources/tasks/TaskTemplateController";
import TaskTemplateModel from "../../../../model/types/basistypes/ressources/tasks/TaskTemplateModel";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {addTask, getTaskUnresolved,} from "../../../../model/ModelController/Resources/tasks/TaskController";
import DateField from "../DateField";
import {Button, CircularProgress, Dialog, Grid, MenuItem, Typography} from "@mui/material";
import {
    GlobalStyledDialogPaper,
    GlobalStyledDivider,
    GlobalStyledTextField
} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import useValidator, {proxiedPropertiesOf} from "../../../ValidatorHook";
import CreateTaskAtConstructionSiteValidator from "./CreateTaskAtConstructionSiteValidator";
import {useTranslation} from "react-i18next";
import {getCurrentDateAsUTCTimestamp, localDateToUTCTimestamp} from "../../../../utility/dateUtil";
import TaskModelUnresolved from "../../../../model/types/basistypes/ressources/tasks/TaskModelUnresolved";

interface PropsType {
    open: boolean,
    task?: TaskModelUnresolved,

    handleClose(): void,

    constructionSite: ConstructionSiteModel,
}

const CreateTaskAtConstructionSiteDialog = (props: PropsType | any) => {
    const {enqueueSnackbar} = useSnackbar();
    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }
    const [localTask, setLocalTask] = useState<TaskModelUnresolved | null>(null);
    const [taskTemplates, setTaskTemplates] = useState<TaskTemplateModel[]>([])
    const fieldDescriptor = proxiedPropertiesOf(localTask)
    const validator = useValidator(CreateTaskAtConstructionSiteValidator, localTask, localTask?.id);
    const {t} = useTranslation();

    const saveToFirebase = () => {
        let errors = validator.performFullValidation()
        if (errors.errorCount > 0) {
            alert(t("pleaseCorrectAllErrors"))
            return
        }
        if (localTask) {
            addTask(localTask)
                .then(() => {
                    showSnackbar("success", t("task.successfullyCreated", {
                        title: localTask.title
                    }))
                    props.handleClose()
                })
                .catch((e) => {
                    showSnackbar("error", t("task.couldNotBeCreated", {
                        title: localTask.title
                    }))
                })
        } else {
            showSnackbar("error", t("task.couldNotBeCreated", {
                title: ""
            }))
        }
    }

    //this function returns a function, to update the Task based on the chosen template
    const buildSetSelectedTaskTemplate = (newTaskTemplate: TaskTemplateModel) => {
        return () => {
            if (localTask) {
                const newTask = {
                    ...localTask,
                    //TODO: fix this dirty pass
                    taskTemplate: {id: `${newTaskTemplate.id}`} as any,
                    description: newTaskTemplate.standardDescription
                }
                setLocalTask(newTask);
            }
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.currentTarget.name;
        let newValue = e.currentTarget.value
        let newState = {...localTask};

        if (e.currentTarget.name === 'plannedFromDate' || e.currentTarget.name === 'plannedToDate') {
            newState[fieldName] = localDateToUTCTimestamp(newValue)

            if (newState) { // @ts-ignore
                setLocalTask(newState)
            }
            return;
        }

        newState[fieldName] = newValue;
        { // @ts-ignore
            setLocalTask(newState);
        }
    }

    const convertTaskDurationUnitToMenuItem = () => {
        const values = Object.values(TaskDurationUnit)

        return values.map((value, index) => {
            return <MenuItem
                value={value}
                key={value}
                onClick={() => {
                    if (localTask) {
                        setLocalTask({...localTask, plannedDurationUnit: value})
                    }
                }}
            >{t("units.time." + value)}</MenuItem>
        })
    }

    //initialize the localTask based on the props
    //if no task is handed over, we create an empty one
    useEffect(() => {
        if (props.task) {
            getTaskTemplates().then((result) => {
                if (result) {
                    setTaskTemplates(result);
                }
                getTaskUnresolved(props.task.id)
                    .then(resolvedTask => setLocalTask(resolvedTask))
                    .catch(reason => {
                        console.debug(reason);
                        setLocalTask(null);
                    })
            })
        } else {
            getTaskTemplates()
                .then((result) => {
                    if (result) {
                        setTaskTemplates(result);
                    }
                    setLocalTask({
                        id: Date.now(),
                        title: "",
                        constructionSite: props.constructionSite,
                        description: "",
                        plannedDuration: 0,
                        resolved: false,
                        plannedDurationUnit: TaskDurationUnit.Hours,
                        plannedFromDate: getCurrentDateAsUTCTimestamp(),
                        plannedToDate: getCurrentDateAsUTCTimestamp(),
                    })
                })
        }
    }, [props.constructionSite, props.task])

    return (<Dialog open={props.open} onClose={props.handleClose}>
            <Grid xs={12} container>
                <GlobalStyledDialogPaper>
                    {(localTask != null && fieldDescriptor != null) ?
                        <>
                            <Grid item xs={12}>
                                <Typography variant={"h6"} align={"center"}>Aufgabe anlegen</Typography>
                                <GlobalStyledDivider/>
                            </Grid>
                            <Grid xs={12} container>
                                <Grid item xs={6}>
                                    <GlobalStyledTextField
                                        label={t(fieldDescriptor.title)}
                                        required
                                        name={fieldDescriptor.title}
                                        value={localTask?.title}
                                        onChange={validator.wrapOnChangeInValidator(onChange)}
                                        error={validator.containsError(fieldDescriptor.title)}
                                        helperText={validator.getErrorMessage(fieldDescriptor.title)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <GlobalStyledTextField
                                        select
                                        label={t("taskTemplate.title")}
                                        value={taskTemplates.find(taskTemplate => taskTemplate.id.toString() === localTask?.taskTemplate?.id)?.title || ""}
                                        name={"taskTemplate"}>
                                        {taskTemplates.map((value, index) => {
                                            return <MenuItem value={value.title} key={value.id}
                                                             onClick={buildSetSelectedTaskTemplate(value)}> {value.title}</MenuItem>
                                        })}
                                    </GlobalStyledTextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <DateField Date={localTask.plannedFromDate}
                                               label={t(fieldDescriptor.plannedFromDate)}
                                               name={fieldDescriptor.plannedFromDate}
                                               error={validator.containsError(fieldDescriptor.plannedFromDate)}
                                               helperText={validator.getErrorMessage(fieldDescriptor.plannedFromDate)}
                                               onChange={(Date) => {
                                                   setLocalTask({...localTask, plannedFromDate: Date})
                                               }}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DateField Date={localTask.plannedToDate}
                                               label={t(fieldDescriptor.plannedToDate)}
                                               name={fieldDescriptor.plannedToDate}
                                               error={validator.containsError(fieldDescriptor.plannedToDate)}
                                               helperText={validator.getErrorMessage(fieldDescriptor.plannedToDate)}
                                               onChange={(Date) => {
                                                   setLocalTask({...localTask, plannedToDate: Date})
                                               }}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <GlobalStyledTextField
                                        label={t(fieldDescriptor.plannedDuration)}
                                        name={fieldDescriptor.plannedDuration}
                                        error={validator.containsError(fieldDescriptor.plannedDuration)}
                                        helperText={validator.getErrorMessage(fieldDescriptor.plannedDuration)}
                                        onChange={validator.wrapOnChangeInValidator(onChange)}
                                        value={localTask.plannedDuration}/>
                                </Grid>

                                <Grid item xs={6}>
                                    <GlobalStyledTextField
                                        select
                                        label={t(fieldDescriptor.plannedDurationUnit)}
                                        name={fieldDescriptor.plannedDurationUnit}
                                        error={validator.containsError(fieldDescriptor.plannedDurationUnit)}
                                        helperText={validator.getErrorMessage(fieldDescriptor.plannedDurationUnit)}
                                        value={localTask.plannedDurationUnit}>
                                        {convertTaskDurationUnitToMenuItem()}
                                    </GlobalStyledTextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <GlobalStyledTextField multiline
                                                           label={t(fieldDescriptor.description)}
                                                           name={fieldDescriptor.description}
                                                           onChange={onChange}
                                                           value={localTask.description}/>
                                </Grid>

                                <Grid item xs={12} container>
                                    <Grid item xs={12}>
                                        <GlobalStyledDivider/>
                                    </Grid>
                                    <Grid item xs={6} container justifyContent={"center"}>
                                        <Button
                                            variant={"contained"}
                                            color={"secondary"}
                                            onClick={saveToFirebase}>
                                            {t("actions.save")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} container justifyContent={"center"}>
                                        <Button
                                            variant={"contained"}
                                            color={"secondary"}
                                            onClick={props.handleClose}>
                                            {t("actions.back")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                        : <Grid container justifyContent={"center"}>
                            <Grid item xs={1}>
                                <CircularProgress size={50}/>
                            </Grid>
                        </Grid>
                    }
                </GlobalStyledDialogPaper>
            </Grid>

    </Dialog>)
}

export default withSnackbar(CreateTaskAtConstructionSiteDialog);
