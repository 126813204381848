import {getFirebase} from "../../FirebaseWrapper";
import getCompanyAffiliation from "../../CompanyAffiliation";
import firebase from "firebase";
import TaskModel from "../../../types/basistypes/ressources/tasks/TaskModel";
import ConstructionSiteModel from "../../../types/basistypes/ressources/ConstructionSiteModel";
import {getDayPlansForDay, getDayPlansForRange} from "../../Planning/DayPlanControllerAdapter";
import TeamModel from "../../../types/TeamModel";
import {resolveConstructionSite} from "../ConstructionSiteController";
import {resolveNestedDocumentList, transformArrayToReference} from "../../FirebaseConverter";
import {registerGenericCollectionListener} from "../../GenericFirebaseSnapshotListener";
import TaskModelUnresolved from "../../../types/basistypes/ressources/tasks/TaskModelUnresolved";

const resolveTask = async (doc: any) => {
	let task: any = doc.data();
	let constructionSite = await task.constructionSite.get();
	task.constructionSite = await resolveConstructionSite(constructionSite);
	if (task.taskTemplate) {
		task.taskTemplate = (await resolveNestedDocumentList([task.taskTemplate]))[0];
	}
	return task as TaskModel;
};

const checkTask = async (taskId: number, resolved: boolean) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		return dbConnection
			.collection(companyId)
			.doc("Task")
			.collection("Task")
			.doc(taskId.toString())
			.update({resolved: resolved})
	}
};

const addTask = async (Task: TaskModelUnresolved) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let fbDoc: any = Task.taskTemplate
			? {
				...Task,
				constructionSite: dbConnection.doc(`/${companyId}/ConstructionSite/ConstructionSite/${Task.constructionSite.id.toString()}`),
				taskTemplate: dbConnection.doc(`/${companyId}/ConstructionSite/ConstructionSite/${Task.taskTemplate.id.toString()}`),
			}
			: {
				...Task,
				constructionSite: dbConnection.doc(`/${companyId}/ConstructionSite/ConstructionSite/${Task.constructionSite.id.toString()}`),
			};
		dbConnection
			.collection(companyId)
			.doc("Task")
			.collection("Task")
			.doc(Task.id.toString())
			.set({...fbDoc, usable: true});
	}
};

const getTaskListView = async (constructionSiteId: number): Promise<Array<TaskModelUnresolved>> => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();
	
	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {

		return dbConnection
			.collection(companyId)
			.doc("Task")
			.collection("Task")
			.where("constructionSite", "==", dbConnection.doc(`/${companyId}/ConstructionSite/ConstructionSite/${constructionSiteId.toString()}`))
			.where("usable", "==", true)
			.get()
			.then(result => result.docs.map(document => document.data() as TaskModelUnresolved))
	}
	return []
}


const registerTaskListenerForConstructionSite = async (
	constructionSiteId: number,
	updateTaskModelState: (tasksToAdd: TaskModelUnresolved[], tasksToModify: TaskModelUnresolved[], tasksToDelete: TaskModelUnresolved[]) => void
) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		let query = dbConnection
			.collection(companyId)
			.doc("Task")
			.collection("Task")
			.where("constructionSite", "==", dbConnection.doc(`/${companyId}/ConstructionSite/ConstructionSite/${constructionSiteId.toString()}`))
			.where("usable", "==", true);

		return await registerGenericCollectionListener<TaskModelUnresolved>(query, updateTaskModelState, async (document) => document.data() as TaskModelUnresolved, "ConstructionSite" + constructionSiteId);
	}
};

const deleteTask = async (taskId: number) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		dbConnection.collection(companyId).doc("Task").collection("Task").doc(taskId.toString()).update({usable: false});
	}
};

const getValidTasksAtDate = async (date: Date) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		const propertiesOf =
			<TObj>(_obj: TObj | undefined = undefined) =>
				<T extends keyof TObj>(name: T): T =>
					name;
		const myInterfaceProperties = propertiesOf<TaskModel>();
		let query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await dbConnection
			.collection(companyId)
			.doc("Task")
			.collection("Task")
			.where("plannedFromDate", "<=", date.getUTCDate())
			.where("plannedToDate", ">=", date.getUTCDate())
			.where("usable", "==", true)
			.get();
		let result: TaskModel[] = [];
		for (let doc of query.docs) {
			result.push(await resolveTask(doc));
		}
		return result;
	}
};

const getValidTasksAtDateForConstructionSite = async (date: number, constructionSites: ConstructionSiteModel[]) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let companyId = await getCompanyAffiliation();
	if (companyId !== undefined) {
		//Determine all of our Assigned Tasks for the date based on the assignments made
		//in the tagesplan
		let dayPlan = await getDayPlansForDay(date);
		let assignedTasks: TaskModel[] = [];

		if (dayPlan) {
			dayPlan.forEach((tagesPlanModel, index) => {
				tagesPlanModel.teams.forEach((team, index) => {
					team.tasks.forEach((task, index) => {
						assignedTasks.push(task);
					});
				});
			});
		}

		if (constructionSites.length === 0) {
			return [];
		}

		let references = await transformArrayToReference(firebase, dbConnection, companyId, "ConstructionSite", constructionSites);
		let batchReads: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>[] = [];
		//Firebase can only handle 10 arrays with a size of 10 in an "in" operator
		while (references.length > 0) {
			let batchOfTen = references.splice(0, 10);

			batchReads.push(
				dbConnection
					.collection(companyId)
					.doc("Task")
					.collection("Task")
					.where("plannedToDate", ">=", date)
					.where("constructionSite", "in", batchOfTen)
					.where("usable", "==", true)
					.where("resolved", "==", false)
					.get()
			);
		}
		//Query the tasks from firebase
		//that are not already assigned in todays Plan

		let documents = await Promise.all(batchReads);
		let tasks: Promise<TaskModel>[] = [];
		for (let query of documents) {
			for (let doc of query.docs) {
				tasks.push(resolveTask(doc));
			}
		}
		return (await Promise.all(tasks)).filter((task) => !assignedTasks.find((assignedTask) => assignedTask.id === task.id));
	}
	return [];
};

interface TaskToTeamAssignment {
	Task: TaskModel;
	Team: TeamModel;
}

const getSummaryOfTaskAssignmentToTeamsOnDays = async (constructionSite: ConstructionSiteModel) => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let returnMap = new Map<number, TaskToTeamAssignment[]>();

	let companyId = await getCompanyAffiliation();
	if (companyId) {
		//retreive all Tagesplane that are within the date range of the construction site
		let affectedDayPlans = await getDayPlansForRange(constructionSite.validFrom, constructionSite.validTo);

		affectedDayPlans.forEach((dayPlan, index) => {
			//Iterate over all Teams
			dayPlan.teams.forEach((team, index) => {
				//Iterate over all Tasks of a Team
				team.tasks.forEach((task: TaskModel, index) => {
					if (task?.constructionSite?.id === constructionSite.id) {
						let arrayForDay = returnMap.get(dayPlan.date);
						if (arrayForDay) {
							returnMap.set(dayPlan.date, [...arrayForDay, {Team: team, Task: task}]);
						} else {
							returnMap.set(dayPlan.date, [{Team: team, Task: task}]);
						}
					}
				});
			});
		});
		return returnMap;
	}
	return returnMap;
};

const getTaskUnresolved = async (taskId: number): Promise<TaskModelUnresolved | null> => {
	const firebase = getFirebase();
	const dbConnection = firebase.firestore();

	let getCompanyId = await getCompanyAffiliation();
	if (getCompanyId !== undefined) {
		return dbConnection
			.collection(getCompanyId)
			.doc("Task")
			.collection("Task")
			.doc(taskId.toString())
			.get()
			.then(result => {
				return result.data() as TaskModelUnresolved
			})
	}
	return null;
};

export {
	addTask,
	checkTask,
	getTaskUnresolved,
	getTaskListView,
	deleteTask,
	getValidTasksAtDate,
	registerTaskListenerForConstructionSite,
	getValidTasksAtDateForConstructionSite,
	getSummaryOfTaskAssignmentToTeamsOnDays,
	resolveTask,
};
export type {TaskToTeamAssignment};
