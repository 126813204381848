import React, {useContext, useEffect, useState} from "react";
import EmployeeModel from "../../../../model/types/basistypes/ressources/EmployeeModel";
import EmployeeMask from "./EmployeeMask";
import {getEmployeesForListView} from "../../../../model/ModelController/Resources/EmployeeController";
import {useSnackbar, VariantType, withSnackbar} from "notistack";
import {AppBarInfoAreaContext} from "../../../Sidebar/AppBarInfoAreaContext";
import ResourceLayout from "../Helper/ResourcesLayout";
import ResourceList from "../Helper/ResourceList";
import {useTranslation} from "react-i18next";
import {Prompt} from "react-router-dom";
import EmployeeListModel from "../../../../model/types/basistypes/ressources/EmployeeListModel";
import {Skeleton} from "@mui/material";


function EmployeeOverview(this: any) {
    const appbarContext = useContext(AppBarInfoAreaContext);
    const [isDirtyIndex, setIsDirtyIndex] = useState(-1)
    const [employeeList, setEmployeeList] = useState<Array<EmployeeListModel>>([])
    const [employeesLoaded, setEmployeesLoaded] = useState<boolean>(false)
    const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState<number>(0)
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null>(null)
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    function initializeOverviewData() {
        getEmployeesForListView()
            .then((employees: Array<EmployeeListModel>) => {
                setEmployeeList(employees);
                if (employees.length > 0) {
                    setSelectedEmployeeId(employees[0].id)
                }
            })
            .finally(() => setEmployeesLoaded(true))
    }

    useEffect(() => {
        initializeOverviewData();
    }, [])


    appbarContext.updateContent(t("employee.manageTitle"));

    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const updateCallback = (index: number) => {
        return (update: EmployeeModel) => {
            const listEntry: EmployeeListModel = {
                id: update.id,
                name: update.name,
                surname: update.surname,
                _isNew: false
            }
            let newState: EmployeeListModel[] = [...employeeList]
            newState[index] = listEntry;
            setEmployeeList(newState);
            setIsDirtyIndex(-1);
            showSnackbar("success",
                t("employee.successfullyCreated", {
                    surname: update.surname,
                    name: update.surname
                }))
        }
    }

    const deleteCallback = (index: number) => {
        let newState: EmployeeListModel[] = employeeList.filter(
            ((value, index1) => {
                return index1 !== index
            })
        )
        setEmployeeList(newState);
        setSelectedEmployeeIndex(0);
        setSelectedEmployeeId(null);
        setIsDirtyIndex(-1);
    }

    const getEmptyEmployee = (): EmployeeListModel => {
        return {
            id: Date.now() * -1,
            surname: t("employee.placeholder.surname"),
            name: t("employee.placeholder.name"),
            _isNew: true
        }
    }

    const addEmployee = () => {
        if (discardChangesInputWindowConfirmDialog()) {
            let newEmployeeList = [...employeeList];
            if (employeeList[selectedEmployeeIndex]?._isNew) {
                newEmployeeList.splice(selectedEmployeeIndex, 1)
            }

            if (employeeList === undefined) {
                const newEmployee = getEmptyEmployee();
                let newState: EmployeeListModel[] = [{
                    id: newEmployee.id,
                    name: newEmployee.name,
                    surname: newEmployee.surname,
                    _isNew: newEmployee._isNew
                }];
                setEmployeeList(newState)
                setSelectedEmployeeIndex(newState.length - 1)
                setSelectedEmployeeId(newEmployee.id)
                setIsDirtyIndex(-1);
            } else {
                const newEmployee = getEmptyEmployee();
                let newEmployeeListModel = {
                    id: newEmployee.id,
                    name: newEmployee.name,
                    surname: newEmployee.surname,
                    _isNew: newEmployee._isNew
                };
                setEmployeeList([...employeeList, newEmployeeListModel])
                setSelectedEmployeeId(newEmployeeListModel.id)
                setSelectedEmployeeIndex(newEmployeeList.length)
                setIsDirtyIndex(-1);
            }

        }
    }

    const setIndex = (resource: any, index: number) => {
        if (selectedEmployeeIndex === index) {
            return;
        }
        if (discardChangesInputWindowConfirmDialog()) {
            setSelectedEmployeeId(null);
            if (employeeList[selectedEmployeeIndex]?._isNew) {
                let newEmployeeList = [...employeeList];
                newEmployeeList.splice(selectedEmployeeIndex, 1)
                setSelectedEmployeeIndex(index);
                setEmployeeList(newEmployeeList)
            }

            setSelectedEmployeeIndex(index);
            setSelectedEmployeeId(employeeList[index].id)
            setIsDirtyIndex(-1);
        } else {
            //do nothing -> stay on old selected resource
        }
    }

    const discardChangesInputWindowConfirmDialog = () => {
        if (isDirtyIndex !== -1 || employeeList[selectedEmployeeIndex]?._isNew) {
            return window.confirm(t("discardChangesDialog.text"));
        } else {
            return true;
        }
    }


    return (
        <>
            <Prompt
                when={isDirtyIndex !== -1 || employeeList[selectedEmployeeIndex]?._isNew === true}
                message={t("discardChangesDialog.leaveOverview")}
            />
            <ResourceLayout
                left={
                    <ResourceList resources={employeeList}
                                  resourcesLoaded={employeesLoaded}
                                  selectedItem={selectedEmployeeIndex}
                                  getListItemText={(employee: EmployeeModel) => {
                                      return employee.surname + ", " + employee.name + ((employee._isNew || employeeList[isDirtyIndex]?.id === employee.id) ? "*" : "")
                                  }}
                                  resourceClickCallBack={setIndex}

                                  addItemCallback={addEmployee}/>
                }
                right={
                    (employeeList.length > 0) ? (selectedEmployeeId == null) ?
                        <Skeleton variant="rectangular" width={210} height={60}/> :
                        <EmployeeMask editEnabled={true} employeeId={selectedEmployeeId}
                                      updateCallback={updateCallback(selectedEmployeeIndex)}
                                      deleteCallback={deleteCallback}
                                      isDirtyCallback={() => setIsDirtyIndex(selectedEmployeeIndex)}
                                      asDialog={false}
                                      closeCallback={() => {
                                      }}/> : <div/>

                }/>
        </>)
}

export default withSnackbar(EmployeeOverview)
