import React, {useEffect, useRef, useState} from "react";
import ConstructionSiteModel from "../../../../model/types/basistypes/ressources/ConstructionSiteModel";
import {
	addConstructionSite,
	deleteConstructionSite,
	getConstructionSite
} from "../../../../model/ModelController/Resources/ConstructionSiteController";
import DateField from "../DateField";
import useValidator, {proxiedPropertiesOf} from "../../../ValidatorHook";
import ConstructionSiteValidator from "./ConstructionSiteValidator";
import {
	Box,
	Checkbox,
	Chip,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";

import {Add, ArrowBackIos, Delete, MoreVert, Save} from "@mui/icons-material";


import {
	checkTask,
	deleteTask,
	registerTaskListenerForConstructionSite,
} from "../../../../model/ModelController/Resources/tasks/TaskController";

import {
	GlobalStyledDivider,
	GlobalStyledPaper,
	GlobalStyledTextField
} from "../../../StyledComponents/GlobalStyled/GlobalStyled";
import {useTranslation} from "react-i18next";
import {useSnackbar, VariantType} from "notistack";
import CreateTaskAtConstructionSiteDialog from "./CreateTaskAtConstructionSiteDialog";
import {getCurrentDateAsUTCTimestamp} from "../../../../utility/dateUtil";
import TeamModel from "../../../../model/types/TeamModel";
import EmployeeModel from "../../../../model/types/basistypes/ressources/EmployeeModel";
import {getConstructionManager,} from "../../../../model/ModelController/Resources/EmployeeController";
import useTheme from "@mui/material/styles/useTheme";
import CustomerModel, {CustomerContactModel} from "../../../../model/types/basistypes/ressources/CustomerModel";
import {getCustomer} from "../../../../model/ModelController/Resources/CustomerController";
import {Action} from "../../../ActionBar/GenericActionBarIconButton";
import GenericActionBar from "../../../ActionBar/GenericActionBar";
import ConstructionSiteModelUnresolved
	from "../../../../model/types/basistypes/ressources/ConstructionSiteModelUnresolved";
import {resolveNestedDocumentList} from "../../../../model/ModelController/FirebaseConverter";
import TaskModelUnresolved from "../../../../model/types/basistypes/ressources/tasks/TaskModelUnresolved";

interface propsType {
	editEnabled: boolean;
	asDialog: boolean;
	constructionSiteId: number;
	currentEmployeeIfApplicableOfBeingAssignedAsConstructionManager: EmployeeModel | undefined;

	updateCallback(ConstructionSite: ConstructionSiteModel): void;

	deleteCallback(constructionSiteId: number): void;

	isDirtyCallback?(): void;

	closeCallback(): void;
}

function ConstructionSiteMask(props: propsType) {
	const theme = useTheme();
	const [localConstructionSite, setLocalConstructionSite] = useState<ConstructionSiteModelUnresolved | null>(null)
	const [localConstructionSiteManager, setLocalConstructionSiteManager] = useState<Array<EmployeeModel>>([]);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [firebaseConstructionManager, setFirebaseConstructionManager] = useState<EmployeeModel[]>([]);
	const [open, setOpen] = useState(false);
	const [selectedTask, setSelectedTask] = useState<TaskModelUnresolved>();
	const [tasks, setTasks] = useState<TaskModelUnresolved[]>([]);
	const [tasksLoaded, setTasksLoaded] = useState<boolean>(false);
	const [tablePage, setTablePage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [customers, setCustomers] = useState<CustomerModel[]>([]);
	const [contacts, setContacts] = useState<CustomerContactModel[]>([]);
	const {t} = useTranslation();
	const {enqueueSnackbar} = useSnackbar();
	const showSnackbar = (variant: VariantType, message: string) => {
		enqueueSnackbar(message, {variant});
	};

	const handleChangePage = (event: any, newPage: number) => {
		setTablePage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setTablePage(0);
	};

	const taskRef = useRef(tasks);

	const fieldDescriptor = proxiedPropertiesOf(localConstructionSite);
	const constructionSiteValidator = useValidator(ConstructionSiteValidator, localConstructionSite, localConstructionSite?.id);

	const updateTasks = (tasksToAdd: TaskModelUnresolved[], tasksToModify: TaskModelUnresolved[], tasksToDelete: TaskModelUnresolved[]) => {
		let newState = [...taskRef.current];

		newState.forEach((task, index) => {
			tasksToModify.forEach((modifiedTask) => {
				if (task.id === modifiedTask.id) {
					newState.splice(index, 1, modifiedTask);
				}
			});
		});

		tasksToDelete.forEach((taskToDelete) => {
			// @ts-ignore
			for (const [index, task] of newState.entries()) {
				if (task.id === taskToDelete.id) {
					newState.splice(index, 1);
					break;
				}
			}
		});
		newState = newState.concat(tasksToAdd);
		taskRef.current = newState;
		setTasks(newState);
		setTasksLoaded(true)
	};

	useEffect(() => {
		getConstructionManager().then((data: any) => {
			if (data !== undefined) {
				setFirebaseConstructionManager(data);
			}
		});

		getCustomer().then((customers) => {
			setCustomers(customers);
		});
	}, []);

	const getEmptyConstructionSite = (constructionSiteId: number): ConstructionSiteModelUnresolved => {
		return {
			id: constructionSiteId * -1,
			title: t("constructionSite.placeholder"),
			validTo: getCurrentDateAsUTCTimestamp(),
			validFrom: getCurrentDateAsUTCTimestamp(),
			constructionManagers: [],
			_isNew: true
		}
	}


	useEffect(() => {
		if (props.constructionSiteId < 0) {
			setIsDirty(true);
			setLocalConstructionSite(getEmptyConstructionSite(props.constructionSiteId))
			if (props.currentEmployeeIfApplicableOfBeingAssignedAsConstructionManager) {
				setLocalConstructionSiteManager([props.currentEmployeeIfApplicableOfBeingAssignedAsConstructionManager])
			} else {
				setLocalConstructionSiteManager([])
			}
		} else {
			getConstructionSite(props.constructionSiteId)
				.then((constructionSiteModel: ConstructionSiteModelUnresolved | null) => {
					if (constructionSiteModel != null) {
						setLocalConstructionSite(constructionSiteModel);
						resolveNestedDocumentList(constructionSiteModel.constructionManagers)
							.then(constructionSiteManager => {
								setLocalConstructionSiteManager(constructionSiteManager);
							})
							.catch(reason => {
								console.debug(reason)
								setLocalConstructionSiteManager([]);
							})
					}
				})

			setIsDirty(false);
		}
	}, [props.constructionSiteId])


	useEffect(() => {
		setContacts(localConstructionSite?.customer?.contacts || []);
	}, [localConstructionSite?.customer]);

	//registerAsynchronus listener for FirebaseTasks
	useEffect(() => {
		if (localConstructionSite == null) {
			return;
		}
		let unsubscribe: any;

		const registerListener = async () => {
			unsubscribe = await registerTaskListenerForConstructionSite(localConstructionSite.id, updateTasks);
		};
		registerListener();
		return function cleanup() {
			if (unsubscribe) {
				taskRef.current = [];
				setTasks([]);
				unsubscribe();
				console.log("unsubscribed from listener");
			}
		};
	}, [localConstructionSite, localConstructionSite?.id]);

	if (localConstructionSite == null || fieldDescriptor == null) {
		return <div/>
	}


	const wrapCallback = (callback: any, windowmode: boolean) => {
		return windowmode
			? () => {
				callback();
				props.closeCallback();
			}
			: callback;
	};

	const saveToFireBase = () => {
		let errors = constructionSiteValidator.performFullValidation();

		if (errors.errorCount > 0) {
			alert(t("pleaseCorrectAllErrors"));
			return;
		}

		if (isAllowedToModify()) {
			const constructionSite: ConstructionSiteModel = {
				...localConstructionSite,
				constructionManagers: localConstructionSiteManager,
				_isNew: false
			}
			console.log(constructionSite)
			addConstructionSite(constructionSite)
				.then((value) => {
					setIsDirty(false);
					console.info("ConstructionSite angelegt.")
				})
				.catch((e) => console.error(e));
			props.updateCallback(constructionSite);
		}
	};

	const deleteFromFireBase = () => {
		if (isAllowedToModify()) {
			deleteConstructionSite(localConstructionSite.id)
				.then((value) => {
					showSnackbar("success", t("constructionSite.successfullyDeleted", {
						title: localConstructionSite.title
					}))
				})
				.catch((e) => console.error(e));
			props.deleteCallback(localConstructionSite.id);

		}
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fieldName: string = e.currentTarget.name;
		let newValue = e.currentTarget.value;
		let newState = { ...localConstructionSite };
		if (props.isDirtyCallback) {
            setIsDirty(true);
			props.isDirtyCallback();
		}
		newState[fieldName] = newValue;
		setLocalConstructionSite(newState);
	};

	const showCreateTaskAtConstructionSiteDialog = () => {
		if (isAllowedToModify()) {
			setOpen(true);
		}
	};

	const dateColumns: any[] = [];

    const constructionSiteActions = [
		{
			icon: <Save />,
			name: "Save",
			tooltip: t("actions.save"),
            callback: wrapCallback(saveToFireBase, props.asDialog),
            disabled: !isDirty,
            actionKey: "s"
        } as Action,
		{
			icon: <Delete />,
			name: "Delete",
			tooltip: t("actions.delete"),
			callback: wrapCallback(deleteFromFireBase, props.asDialog),
		},
    ]

	if (props.asDialog) {
        constructionSiteActions.push({
            icon: <ArrowBackIos/>,
            name: 'Back',
            tooltip: t("actions.back"),
            callback: props.closeCallback
        })
	}

    const taskActions = [
        {
            icon: <Add/>, name: 'Task', tooltip: t("actions.task"), callback: () => {
                showCreateTaskAtConstructionSiteDialog();
            }
        },
    ];


	const getConstructionManager2 = (team: TeamModel): string => {
		let constructionManagers: string = "";

		team.constructionSites.map((constructionSite) =>
			constructionSite.constructionManagers.map((constructionManager) => (constructionManagers += constructionManager.surname + ", " + constructionManager.name + "\n"))
		);

		if (constructionManagers.length > 0) {
			return constructionManagers;
		}

		return t("teamWorkPlanPDF.noConstructionManagerSet");
	};

	// Checks if the current user is assigned as construction site manager.
	// If the user is assigned, he will be allowed to manipulate the construction site.
	// If the user is not assigned, he well be allowed to manipulate the construction site after confirming the dialog.
	// In case the user does not confirm the dialog, all actions are canceled.
	const isAllowedToModify = () => {
		let isAssignedAsConstructionSiteManager =
			localConstructionSiteManager.filter((constructionSiteManager) => constructionSiteManager.id === props.currentEmployeeIfApplicableOfBeingAssignedAsConstructionManager?.id).length > 0;

		if (!isAssignedAsConstructionSiteManager) {
			return window.confirm(t("constructionSite.constructionSiteManagerMismatch.description"));
		}
		return true;
	};

	const handleChange = (event: any) => {
		let newConstructionManagers: Array<EmployeeModel> = [];
		const inputValue: Array<number> = event.target.value;

		inputValue.forEach((constructionSiteManagerId) => {
			const constructionSiteManager =
				firebaseConstructionManager[firebaseConstructionManager.findIndex((constructionSiteManager) => constructionSiteManager.id === constructionSiteManagerId)];
			newConstructionManagers.push(constructionSiteManager);
		});

		setLocalConstructionSiteManager(newConstructionManagers);

		if (props.isDirtyCallback) {
            setIsDirty(true);
			props.isDirtyCallback();
		}
	};

	const getLabel = (value: number) => {
		if (firebaseConstructionManager) {
			const manager = firebaseConstructionManager[firebaseConstructionManager.findIndex((constructionSiteManager) => constructionSiteManager.id === value)];
			if (manager) {
				return manager.surname;
			}
		}
		return "";
	};

	const handleCheckTask = (task: TaskModelUnresolved, e: React.ChangeEvent<HTMLInputElement>) => {
		if (isAllowedToModify()) {
			checkTask(task.id, e.currentTarget.checked)
				.then(() => {
					showSnackbar(
						"success",
						t("task.successfullyUpdated", {
							title: task.title,
						})
					);
				})
				.catch(reason => {
					console.debug(reason);
				})
		}
	};

	const handleDeleteTask = (task: TaskModelUnresolved) => {
		if (isAllowedToModify()) {
			deleteTask(task.id).then((value) => {
				showSnackbar(
					"success",
					t("task.successfullyDeleted", {
						title: task.title,
					})
				);
			});
		}
	};

	const handleOpenTask = (task: TaskModelUnresolved) => {
		if (isAllowedToModify()) {
			setOpen(true);
			setSelectedTask(task)
		}
	};

	return (
		<Grid container xs={12}>
			<Grid item container xs={12}>
				<GlobalStyledPaper>
					<Grid container position={"sticky"} alignItems={"flex-start"} xs={12} display={"flex"}>
                        <GenericActionBar actions={constructionSiteActions}/>
						<Grid item xs={12}>
							<Typography variant={"h6"} align={"center"}>
								{t("constructionSite.title")}: {localConstructionSite.title}
							</Typography>
							<GlobalStyledDivider />
						</Grid>
						<Grid xs={12} container>
							<Grid item xs={6}>
								<GlobalStyledTextField
									name={fieldDescriptor.title}
									label={t(fieldDescriptor.title)}
									required
									fullWidth
									value={localConstructionSite.title}
									error={constructionSiteValidator.containsError(fieldDescriptor.title)}
									onChange={constructionSiteValidator.wrapOnChangeInValidator(onChange)}
									helperText={constructionSiteValidator.getErrorMessage(fieldDescriptor.title)}
									variant={"outlined"}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormControl
									sx={{
										margin: theme.spacing(1),
										width: `calc(100% - 4*${theme.spacing(1)})`,
										padding: `calc(1/2*${theme.spacing(1)})`,
									}}
								>
									<InputLabel id="multiple-constructionSiteManager-label"
												key={"constructionSiteManager"}>
										{t("constructionSiteManager")}
									</InputLabel>
									<Select
										labelId="multiple-constructionSiteManager-label"
										id="multiple-constructionSiteManager"
										multiple
										fullWidth
										variant={"outlined"}
										input={<OutlinedInput label={t("constructionSiteManager")}/>}
										value={localConstructionSiteManager.map((constructionSiteManager) => constructionSiteManager.id)}
										renderValue={(selected) => (
											<Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
												{selected.map((value) => (firebaseConstructionManager?.length === 0 ?
													<CircularProgress/> : <Chip key={value} label={getLabel(value)}/>))}
											</Box>
										)}
										onChange={handleChange}
									>
										{firebaseConstructionManager.map((constructionSiteManager) => (
											<MenuItem key={constructionSiteManager.id}
													  value={constructionSiteManager.id}>
												<Typography
													variant={"subtitle2"}>{constructionSiteManager.surname + ", " + constructionSiteManager.name}</Typography>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl
									sx={{
										margin: theme.spacing(1),
										width: `calc(100% - 4*${theme.spacing(1)})`,
										padding: `calc(1/2*${theme.spacing(1)})`,
									}}
								>
									<InputLabel id="multiple-customer-label" key={"customer"}>
										{t("customer.title")}
									</InputLabel>
									<Select
										labelId="multiple-customer-label"
										id="multiple-customer"
										fullWidth
										variant={"outlined"}
										input={<OutlinedInput label={t("customer")} />}
										value={localConstructionSite?.customer?.id || 0}
									>
										{customers.map((customer) => (
											<MenuItem
												key={customer.id}
												value={customer.id}
												onClick={() => {
													if (props?.isDirtyCallback) {
														setIsDirty(true);
														props?.isDirtyCallback();
													}
													setLocalConstructionSite({ ...localConstructionSite, customer: customer, customerContact: undefined });
												}}
											>
												<Typography variant={"subtitle2"}>{customer.name + ", " + customer.address.city}</Typography>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl
									sx={{
										margin: theme.spacing(1),
										width: `calc(100% - 4*${theme.spacing(1)})`,
										padding: `calc(1/2*${theme.spacing(1)})`,
									}}
								>
									<InputLabel id="multiple-customer-label" key={"customer"}>
										{t("customer.customer-contact")}
									</InputLabel>
									<Select
										labelId="multiple-customer-label"
										id="multiple-customer"
										fullWidth
										variant={"outlined"}
										input={<OutlinedInput label={t("customer.customer-contact")}/>}
										value={localConstructionSite?.customerContact?.id || 0}
									>
										{contacts.map((contact) => (
											<MenuItem
												key={contact.id}
												value={contact.id}
												onClick={() => {
													if (props?.isDirtyCallback) {
														setIsDirty(true);
														props?.isDirtyCallback();
													}
													setLocalConstructionSite({ ...localConstructionSite, customerContact: contact });
												}}
											>
												<Typography variant={"subtitle2"}>{contact.name + ", " + contact.position}</Typography>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<DateField
								Date={localConstructionSite.validFrom}
								label={t(fieldDescriptor.validFrom)}
								error={constructionSiteValidator.containsError(fieldDescriptor.validFrom)}
								helperText={constructionSiteValidator.getErrorMessage(fieldDescriptor.validFrom)}
								onChange={(Date) => {
									if (props.isDirtyCallback) {
                                               setIsDirty(true);
										props.isDirtyCallback();
									}
									setLocalConstructionSite({ ...localConstructionSite, validFrom: Date });
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<DateField
								Date={localConstructionSite.validTo}
								label={t(fieldDescriptor.validTo)}
								error={constructionSiteValidator.containsError(fieldDescriptor.validTo)}
								helperText={constructionSiteValidator.getErrorMessage(fieldDescriptor.validTo)}
								onChange={(Date) => {
									if (props.isDirtyCallback) {
                                               setIsDirty(true);
										props.isDirtyCallback();
									}
									setLocalConstructionSite({ ...localConstructionSite, validTo: Date });
								}}
							/>
						</Grid>

						<Grid item xs={12}>
							<GlobalStyledDivider />
						</Grid>
					</Grid>
					<Grid item container xs={12}>
                        <GenericActionBar actions={taskActions}/>
						<Grid item xs={12}>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow sx={{borderBottom: "2px solid", borderBottomColor: "divider"}}>
											<TableCell sx={{borderBottom: "2px solid", borderBottomColor: "divider"}}>
												<Typography variant={"h6"} align={"center"}>
													{t("task.title")}
												</Typography>
											</TableCell>
											{dateColumns}
										</TableRow>
									</TableHead>
									<TableBody sx={{overflow: "auto"}}>
										{!tasksLoaded ? [...Array(10)].map(() => <TableRow><Skeleton
												height={"5vh"}/></TableRow>) :
											tasks.slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage).map((task, index) => {
												return (
													<TableRow>
														<TableCell
															sx={{
																width: "15vw",
																borderBottom: "2px solid",
																borderBottomColor: "divider",
															}}
														>
															<Grid container direction={"row"}
																  justifyContent={"center"}>
																<Grid item xs={8}>
																	<FormControlLabel
																		control={<Checkbox checked={task.resolved}
																						   onChange={(e) => handleCheckTask(task, e)}
																						   name={fieldDescriptor.resolved}/>}
																		label={<Typography
																			style={task.resolved ? {textDecoration: "line-through"} : {}}>{task.title} </Typography>}
																	/>
																</Grid>
																<Grid item xs={2}>
																	<IconButton
																		onClick={() => {
																			handleDeleteTask(task);
																		}}
																	>
																		<Delete/>
																	</IconButton>
																</Grid>
																<Grid item xs={2}>
																	<IconButton
																		onClick={() => {
																			handleOpenTask(task);
																		}}
																	>
																		<MoreVert/>
																	</IconButton>
																</Grid>
															</Grid>
														</TableCell>
													</TableRow>
												);
											})
										}
									</TableBody>
								</Table>
								<TablePagination
									sx={{borderBottom: "2px solid", borderBottomColor: "divider"}}
									rowsPerPageOptions={[10, 25, 50, 100]}
									count={tasks.length}
									rowsPerPage={rowsPerPage}
									page={tablePage}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</TableContainer>
						</Grid>
					</Grid>
					{open ? (
						<CreateTaskAtConstructionSiteDialog
							open={open}
							handleClose={() => {
								setOpen(false);
								setSelectedTask(undefined);
							}}
							constructionSite={localConstructionSite}
							task={selectedTask}
							//key={selectedTask?.id}
						/>
					) : (
						<div />
					)}
				</GlobalStyledPaper>
			</Grid>
		</Grid>
	);
}

export default ConstructionSiteMask;
