import React, {useEffect, useState} from "react";
import EmployeeModel from "../../../../model/types/basistypes/ressources/EmployeeModel";
import {addEmployee, deleteEmployee, getEmployee} from "../../../../model/ModelController/Resources/EmployeeController";
import DrivingLicenseClassModel from "../../../../model/types/basistypes/ressources/DrivingLicenseClassModel";
import DrivingLicenseClassSelectionDialog from "../DrivingLicenseClasses/DrivingLicenseClassSelectionDialog";
import AppointmentMask from "../../../Time/AppointmentMask";
import {ResourceType} from "../../../../model/types/basistypes/times/AppointmentModel";
import AppointmentList from "../../../Time/AppointmentList";
import DateField from "../DateField";
import useValidator, {proxiedPropertiesOf} from "../../../ValidatorHook";
import EmployeeValidator from "./EmployeeValidator";
import FullScreenDialogSidebar from "../Helper/FullScreenDialogSidebar";
import {Grid, Skeleton, Typography,} from "@mui/material";
import {Add, Clear, Delete, Save} from "@mui/icons-material";
import {ResourceStyledDriversLicenceChip} from "../../../StyledComponents/ComponentStyled/ResourceStyled";
import {useTranslation} from "react-i18next";
import GenericActionBar from "../../../ActionBar/GenericActionBar";
import {Action} from "../../../ActionBar/GenericActionBarIconButton";
import {getCurrentDateAsUTCTimestamp} from "../../../../utility/dateUtil";
import EmployeeModelUnresolved from "../../../../model/types/basistypes/ressources/EmployeeModelUnresolved";
import {useSnackbar, VariantType} from "notistack";
import {resolveNestedDocumentList} from "../../../../model/ModelController/FirebaseConverter";
import {
    GlobalStyledDivider,
    GlobalStyledPaper,
    GlobalStyledTextField
} from "../../../StyledComponents/GlobalStyled/GlobalStyled";

interface propsType {
    editEnabled: boolean
    asDialog: boolean
    employeeId: number

    updateCallback(employee: EmployeeModel): void

    deleteCallback(employeeId: number): void

    isDirtyCallback?(): void

    closeCallback(): void

}

function EmployeeMask(props: propsType) {
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [localEmployee, setLocalEmployee] = useState<EmployeeModelUnresolved | null>(null)
    const [drivingLicenseClasses, setDrivingLicenseClasses] = useState<Array<DrivingLicenseClassModel> | null>(null);
    const [open, setOpen] = useState(false);
    const [openAppointment, setOpenAppointment] = useState(false);
    const fieldDescriptor = proxiedPropertiesOf(localEmployee);
    const validator = useValidator(EmployeeValidator, localEmployee, localEmployee?.id);
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const showSnackbar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, {variant})
    }

    const getEmptyEmployee = (localEmployeeId: number): EmployeeModelUnresolved => {
        return {
            id: localEmployeeId * -1,
            active: true,
            drivingLicenseClasses: [],
            birthday: getCurrentDateAsUTCTimestamp(),
            surname: t("employee.placeholder.surname"),
            name: t("employee.placeholder.name"),
        }
    }

    useEffect(() => {
        if (props.employeeId < 0) {
            setIsDirty(true);
            setLocalEmployee(getEmptyEmployee(props.employeeId))
            setDrivingLicenseClasses([]);
        } else {
            getEmployee(props.employeeId.toString())
                .then((employee: EmployeeModelUnresolved | null) => {
                    if (employee != null) {
                        setLocalEmployee(employee)
                        resolveNestedDocumentList(employee.drivingLicenseClasses)
                            .then(result => {
                                setDrivingLicenseClasses(result);
                            })
                            .catch(reason => {
                                console.debug(reason)
                                setDrivingLicenseClasses([])
                            })
                    }
                })
                .catch(reason => {
                    console.debug(reason);
                    setLocalEmployee(null);
                })
            setIsDirty(false);
        }
    }, [props.employeeId])

    if (localEmployee == null || fieldDescriptor == null) {
        return <div/>
    }


    const wrapCallback = (callback: any, windowmode: boolean) => {
        return (windowmode) ?
            () => {
                callback()
                props.closeCallback();
            } : callback
    }

    const saveToFireBase = () => {
        let errors = validator.performFullValidation()
        if (errors.errorCount > 0) {
            alert("Beheben Sie vor dem Speichern alle Fehler");
            return;
        }

        const employee: EmployeeModel = {
            ...localEmployee,
            drivingLicenseClasses: drivingLicenseClasses == null ? [] : drivingLicenseClasses,
            _isNew: false
        }

        addEmployee(employee)
            .then(value => {
                console.info("Mitarbeiter angelegt.")
                setIsDirty(false);
            })
            .catch(e => console.error(e))

        props.updateCallback(employee)
    }

    const deleteFromFireBase = () => {
        deleteEmployee(localEmployee.id).then(value => {
                console.info("Mitarbeiter gelöscht.")
                showSnackbar("success", t("employee.successfullyDeleted", {
                    surname: localEmployee.surname,
                    name: localEmployee.name
                }))
            }
        ).catch(e => console.error(e))
        props.deleteCallback(localEmployee.id)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.currentTarget.name;
        const newValue = e.currentTarget.value
        let newState: EmployeeModelUnresolved = {...localEmployee};
        if (props.isDirtyCallback) {
            setIsDirty(true);
            props.isDirtyCallback();
        }
        //TODO fix this
        // @ts-ignore
        newState[fieldName] = newValue;
        setLocalEmployee(newState)
    }

    const handleDelete = (index: number) => {
        return () => {
            let newEmployee = {...localEmployee}
            newEmployee.drivingLicenseClasses.splice(index, 1)
            setLocalEmployee(newEmployee)

            if (props.isDirtyCallback) {
                props.isDirtyCallback();
                setIsDirty(true);
            }
        }
    }

    const addDrivingLicenseClass = (drivingLicenseClass: DrivingLicenseClassModel) => {
        let newDrivingLicenseClass: DrivingLicenseClassModel[] = [...(drivingLicenseClasses == null ? [] : drivingLicenseClasses)];
        newDrivingLicenseClass.push(drivingLicenseClass);

        setDrivingLicenseClasses(newDrivingLicenseClass)
        if (props.isDirtyCallback) {
            props.isDirtyCallback()
            setIsDirty(true);
        }

        //Dialog schließen
        setOpen(false)
    }

    const appointmentActions: Array<Action> = [
        {
            icon: <Add/>, name: 'Appointment', tooltip: t("actions.appointment"), callback: () => {
                setOpenAppointment(true)
            }
        },
    ];

    const drivingLicenceClassActions: Array<Action> = [
        {
            icon: <Add/>, name: 'DrivingLicense', tooltip: t("actions.drivingLicense"), callback: () => {
                setOpen(true)
            }
        }];

    const employeeActions = [];

    if (!props.asDialog) {
        employeeActions.push(
            {
                icon: <Save/>,
                name: 'Save',
                tooltip: t("actions.save"),
                callback: wrapCallback(saveToFireBase, props.asDialog),
                disabled: !isDirty,
                actionKey: "s"
            } as Action,
            {
                icon: <Delete/>,
                name: 'Delete',
                tooltip: t("actions.delete"),
                callback: wrapCallback(deleteFromFireBase, props.asDialog)
            },
        );
    }
    return (
        <Grid container xs={12}>
            <Grid item xs={12}>
                {
                    (props.asDialog) ?
                        <FullScreenDialogSidebar iconBackAction={props.closeCallback}
                                                 iconSaveAction={wrapCallback(saveToFireBase, props.asDialog)}
                                                 iconDeleteAction={wrapCallback(deleteFromFireBase, props.asDialog)}
                                                 title={t("employee.manageTitle")}/>
                        : <div/>
                }
            </Grid>
            <Grid item container xs={6}>
                <GlobalStyledPaper>
                    <Grid item container
                          alignItems={"flex-start"}
                          xs={12}>
                        <GenericActionBar actions={employeeActions}/>
                        <Grid item xs={12}>
                            <Typography variant={"h6"} align={"center"}>
                                {t("employee.title") + `: ${localEmployee.surname}, ${localEmployee.name}`}</Typography>
                            <GlobalStyledDivider/>
                        </Grid>
                        <Grid item xs={6}>
                            <GlobalStyledTextField
                                name={fieldDescriptor.surname}
                                required
                                label={t(fieldDescriptor.surname)}
                                value={localEmployee.surname}
                                onChange={validator.wrapOnChangeInValidator(onChange)}
                                error={validator.containsError(fieldDescriptor.surname)}
                                helperText={validator.getErrorMessage(fieldDescriptor.surname)}
                                variant={"outlined"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <GlobalStyledTextField
                                name={fieldDescriptor.name}
                                required
                                label={t(fieldDescriptor.name)}
                                value={localEmployee.name}
                                onChange={validator.wrapOnChangeInValidator(onChange)}
                                error={validator.containsError(fieldDescriptor.name)}
                                helperText={validator.getErrorMessage(fieldDescriptor.name)}
                                variant={"outlined"}/>
                        </Grid>
                        <Grid item xs={6}>
                            <DateField Date={localEmployee.birthday}
                                       label={t(fieldDescriptor.birthday)}
                                       error={validator.containsError(fieldDescriptor.birthday)}
                                       helperText={validator.getErrorMessage(fieldDescriptor.birthday)}
                                       onChange={(Date) => {
                                           if (props.isDirtyCallback) {
                                               setIsDirty(true);
                                               props.isDirtyCallback();
                                           }
                                           setLocalEmployee({...localEmployee, birthday: Date})
                                       }}/></Grid>

                    </Grid>
                    <Grid item container>
                        <GenericActionBar actions={drivingLicenceClassActions}/>
                        <Grid item xs={12}>
                            <Typography variant={"h6"} align={"center"}>{t("drivingLicense.classes")}</Typography>
                            <GlobalStyledDivider/>
                        </Grid>
                        {
                            (drivingLicenseClasses == null) ?
                                <Skeleton variant="rectangular" width={210} height={60}/> :
                                drivingLicenseClasses.map(
                                    (value, index) => {
                                        return (
                                            <Grid item xs={4} sm={4} md={4} xl={3}><ResourceStyledDriversLicenceChip
                                                label={value.description}
                                                onDelete={handleDelete(index)}
                                                deleteIcon={<Clear/>}/></Grid>)
                                    }
                                )
                        }
                    </Grid>
                </GlobalStyledPaper>
            </Grid>
            <Grid item xs={6}>
                <GlobalStyledPaper>
                    <Grid item xs={12}>
                        <GenericActionBar actions={appointmentActions}/>
                        <Typography variant={"h6"}
                                    align={"center"}> {t("appointmentsFor") + `: ${localEmployee.surname}, ${localEmployee.name}`} </Typography>
                        <GlobalStyledDivider/>
                    </Grid>
                    <Grid item xs={12}>
                        {//TODO: change type from any to modelUnresolved
                        }
                        <AppointmentList type={ResourceType.EMPLOYEE} resource={localEmployee as any}
                                         Id={localEmployee.id}
                        />
                    </Grid>
                </GlobalStyledPaper>
            </Grid>
            {(open) ? <DrivingLicenseClassSelectionDialog open={open}
                                                          classes={drivingLicenseClasses == null ? [] : drivingLicenseClasses}
                                                          callBack={addDrivingLicenseClass}
                                                          closeCallback={() => setOpen(false)}/> : ""}
            {(openAppointment) ? <AppointmentMask open={openAppointment}
                                                  editEnabled={true}
                                                  resource={localEmployee as any}
                                                  resourceType={ResourceType.EMPLOYEE}
                                                  updateCallback={(termin) => {
                                                      setOpenAppointment(false);
                                                  }}
                                                  deleteCallback={() => setOpenAppointment(false)}
                                                  closeCallback={() => setOpenAppointment(false)}/> : ""}
        </Grid>
    )
}

// @ts-ignore
export default EmployeeMask;
