import DayPlanModel from "../../types/DayPlanModel";
import {deleteCollection, getFirebase} from "../FirebaseWrapper";
import getCompanyAffiliation from "../CompanyAffiliation";
import {utcTimestampToFormattedISOString} from "../../../utility/dateUtil";
import TeamModel from "../../types/TeamModel";
import firebase from "firebase";
import EmployeeModel from "../../types/basistypes/ressources/EmployeeModel";
import VehicleModel from "../../types/basistypes/ressources/VehicleModel";
import EquipmentModel from "../../types/basistypes/ressources/EquipmentModel";
import ConstructionSiteModel from "../../types/basistypes/ressources/ConstructionSiteModel";
import TaskModel from "../../types/basistypes/ressources/tasks/TaskModel";
import {
    getDayPlansForDay as getDayPlansForDayV1,
    getDayPlansForRange as getDayPlansForRangeV1
} from "./DayPlanController";
import DocumentReference = firebase.firestore.DocumentReference;
import DocumentData = firebase.firestore.DocumentData;

interface FirebaseRefable {
    __firebaseRef: DocumentReference<DocumentData>
}

interface EmployeeDayPlanModel extends Omit<EmployeeModel, "birthday" | "drivingLicenseClasses" | "_isNew">, FirebaseRefable {
    drivingLicenseClasses: DocumentReference<DocumentData>[]
}

interface VehicleDayPlanModel extends Omit<VehicleModel, "producer" | "nickname" | "vehicleDescription" | "drivingLicenseClasses" | "_isNew">, FirebaseRefable {
    drivingLicenseClasses: DocumentReference<DocumentData>[]
}

interface EquipmentDayPlanModel extends Omit<EquipmentModel, "components" | "hasComponents" | "investmentNumber" | "nickname" | "purchaseDate" | "serialNumber" | "_isNew">, FirebaseRefable {
}

interface TaskDayPlanModel extends Omit<TaskModel, "plannedDuration" | "plannedDurationUnit" | "taskTemplate" | "constructionSite" | "description" | "resolved" | "title">, FirebaseRefable {
}

interface ConstructionSiteDayPlanModel extends Omit<ConstructionSiteModel, "constructionManagers" | "_isNew" | "address" | "customer" | "title" | "customerContact">, FirebaseRefable {

}

interface DayPlanV2TeamModel extends Omit<TeamModel, "tasks" | "foremans" | "constructionSites" | "employees" | "vehicles" | "equipment">, FirebaseRefable {
    date: number
    tasks: TaskDayPlanModel[]
    constructionSites: ConstructionSiteDayPlanModel[]
    foremans: EmployeeDayPlanModel[]
    employees: EmployeeDayPlanModel[],
    vehicles: VehicleDayPlanModel[],
    equipment: EquipmentDayPlanModel[],
    __firebaseReferencedObjects: DocumentReference<DocumentData>[]
}

const addTeamToDayPlan = async (dayPlanRef: DocumentReference<DocumentData>, team: TeamModel, date: number) => {
    const teamRef = dayPlanRef.collection("Teams").doc(team.id + "");

    const localTeam: DayPlanV2TeamModel = {} as DayPlanV2TeamModel;
    localTeam.__firebaseReferencedObjects = []
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation() || "";

    if (companyId) {
        localTeam.date = date;
        localTeam.name = team.name || "";
        localTeam.id = team.id;

        localTeam.foremans = team.foremans.map((foreman) => {
            let ref = dbConnection.collection(companyId).doc("Employee").collection("Employee").doc(foreman.id + "")
            localTeam.__firebaseReferencedObjects.push(ref)
            return {
                id: foreman.id,
                name: foreman.name,
                surname: foreman.surname,
                drivingLicenseClasses: foreman.drivingLicenseClasses.map((drivingLicenseClass) => {
                    return dbConnection.collection(companyId).doc("DrivingLicenseClass").collection("DrivingLicenseClass").doc(drivingLicenseClass.id + "");
                }),
                __firebaseRef: ref
            } as EmployeeDayPlanModel
        })

        localTeam.constructionSites = await Promise.all(team.constructionSites.map(async (constructionSite) => {
            let ref = dbConnection.collection(companyId).doc("ConstructionSite").collection("ConstructionSite").doc(constructionSite.id + "")
            localTeam.__firebaseReferencedObjects.push(ref)

            let constructionManagersResolved = await Promise.all(constructionSite.constructionManagers.map(async constructionSiteManager => {
                if (constructionSiteManager.get !== undefined) {
                    constructionSiteManager = (await constructionSiteManager.get()).data() as EmployeeModel;
                }

                let constructionManagerRef = dbConnection.collection(companyId).doc("Employee").collection("Employee").doc(constructionSiteManager.id + "")

                const resolved = {
                    id: constructionSiteManager.id,
                    name: constructionSiteManager.name,
                    surname: constructionSiteManager.surname,
                    drivingLicenseClasses: constructionSiteManager.drivingLicenseClasses?.map((drivingLicenseClass) => {
                        return dbConnection.collection(companyId).doc("DrivingLicenseClass").collection("DrivingLicenseClass").doc(drivingLicenseClass.id + "");
                    }),
                    __firebaseRef: constructionManagerRef
                } as EmployeeDayPlanModel

                localTeam.__firebaseReferencedObjects.push(constructionManagerRef)
                return resolved;
            }))

            return {
                __firebaseRef: ref,
                id: constructionSite.id,
                validTo: constructionSite.validTo,
                validFrom: constructionSite.validFrom,
                title: constructionSite.title,
                constructionManagers: constructionManagersResolved,
            } as ConstructionSiteDayPlanModel
        }))

        localTeam.tasks = team.tasks.map((task) => {
            let ref = dbConnection.collection(companyId).doc("Task").collection("Task").doc(task.id + "");
            localTeam.__firebaseReferencedObjects.push(ref)
            return {
                __firebaseRef: ref,
                id: task.id,
                plannedToDate: task.plannedToDate,
                plannedFromDate: task.plannedToDate
            } as TaskDayPlanModel;
        })

        localTeam.employees = team.employees.map((employee) => {
            let ref = dbConnection.collection(companyId).doc("Employee").collection("Employee").doc(employee.id + "")
            localTeam.__firebaseReferencedObjects.push(ref)
            return {
                id: employee.id,
                name: employee.name,
                surname: employee.surname,
                drivingLicenseClasses: employee.drivingLicenseClasses.map((drivingLicenseClass) => {
                    return dbConnection.collection(companyId).doc("DrivingLicenseClass").collection("DrivingLicenseClass").doc(drivingLicenseClass.id + "");
                }),
                __firebaseRef: ref
            } as EmployeeDayPlanModel
        })

        localTeam.vehicles = team.vehicles.map((vehicle) => {
            let ref = dbConnection.collection(companyId).doc("Vehicle").collection("Vehicle").doc(vehicle.id + "")
            localTeam.__firebaseReferencedObjects.push(ref)
            return {
                id: vehicle.id,
                licensePlace: vehicle.licensePlace,
                drivingLicenseClasses: vehicle.drivingLicenseClasses.map((drivingLicenseClass) => {
                    return dbConnection.collection(companyId).doc("DrivingLicenseClass").collection("DrivingLicenseClass").doc(drivingLicenseClass.id + "");
                }),
                __firebaseRef: ref
            } as VehicleDayPlanModel
        })

        localTeam.equipment = team.equipment.map((equipment) => {
            let ref = dbConnection.collection(companyId).doc("Equipment").collection("Equipment").doc(equipment.id + "")
            localTeam.__firebaseReferencedObjects.push(ref)
            return {
                id: equipment.id,
                producer: equipment.producer,
                type: equipment.type,
                __firebaseRef: ref
            } as EquipmentDayPlanModel
        })

        localTeam.__firebaseReferencedObjects = Array.from(new Set(localTeam.__firebaseReferencedObjects))

        //upsertTeam  transformed to Firebase
        return teamRef.set(localTeam);
    }
}

const resolveTeamView = async (dayPlanDate: number, teamId: number): Promise<TeamModel> => {
    const firebase = getFirebase();
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        const teamRef = dbConnection
            .collection(companyId)
            .doc("DayPlanV2")
            .collection("DayPlanV2")
            .doc(utcTimestampToFormattedISOString(dayPlanDate)).collection("Teams").doc(teamId + "");


        const teamV2Doc = await teamRef.get()

        const teamV2: DayPlanV2TeamModel = teamV2Doc.data() as DayPlanV2TeamModel;


        const promises: Promise<any>[] = [];

        const teamResult = {} as TeamModel;

        const resolvedTasks = (await Promise.all(teamV2.tasks.map(taskV2 => {
            //TODO retreive Construction Site Label
            return taskV2.__firebaseRef.get()
        }))).map(task => task.data() as TaskModel)


        const resolvedConstructionSites = (await Promise.all(teamV2.constructionSites.map(site => {
            return site.__firebaseRef.get()
        }))).map(site => site.data() as ConstructionSiteModel)

        return {
            id: teamV2.id,
            name: teamV2.name,
            vehicles: teamV2.vehicles as unknown as VehicleModel[],
            employees: teamV2.employees as unknown as EmployeeModel[],
            equipment: teamV2.equipment as unknown as EquipmentModel[],
            foremans: teamV2.foremans.map(foreman => {
                return {
                    id: foreman.id,
                    ref: foreman,
                    name: foreman.name,
                    surname: foreman.surname
                } as unknown as EmployeeModel
            }),
            tasks: resolvedTasks,
            constructionSites: resolvedConstructionSites
        } as unknown as TeamModel


    }

    return {} as TeamModel;


}

const addDayPlan = async (dayPlan: DayPlanModel) => {

    const firebase = getFirebase();
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        const dayPlanRef = dbConnection
            .collection(companyId)
            .doc("DayPlanV2")
            .collection("DayPlanV2")
            .doc(utcTimestampToFormattedISOString(dayPlan.date))

        //TODO handle incremental changes to an existing DayPlan better
        try {
            await deleteCollection(dbConnection, dayPlanRef.collection("Teams"), 10)
        } catch (e) {
            //doNothing
            debugger;
        }
        //upsert DayPlan to fireBase
        await dayPlanRef.set({date: dayPlan.date})

        const teamPromises = dayPlan.teams.map((team) => {
            return addTeamToDayPlan(dayPlanRef, team, dayPlan.date)
        })

        const result = await Promise.allSettled(teamPromises)

        const overallResult = result.reduce((previousValue, currentValue) => {
            return (previousValue.status === 'rejected') ? (previousValue) : currentValue

        })

        return overallResult.status;

    }
};

const deleteDayPlan = async (dayPlan: DayPlanModel) => {

    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation() || "";

    if (companyId) {
        const dayPlanRef = dbConnection
            .collection(companyId)
            .doc("DayPlanV2")
            .collection("DayPlanV2")
            .doc(utcTimestampToFormattedISOString(dayPlan.date))


        await deleteCollection(dbConnection, dayPlanRef.collection("Teams"), 10)
        await dayPlanRef.delete();
    }
};

const copyDayPlanToDate = async (dayPlan: DayPlanModel, targetDate: number): Promise<DayPlanModel> => {

    let newTeams: TeamModel[] = [];

    dayPlan.teams.forEach((team, index) => {
        let newConstructionSites: ConstructionSiteModel[] = [];
        let newTasks: TaskModel[] = [];

        team.constructionSites.forEach((constructionSite, index) => {
            if (constructionSite.validFrom <= targetDate && constructionSite.validTo >= targetDate) {
                newConstructionSites.push(constructionSite);
            }
        });

        team.tasks.forEach((task, index) => {
            if (task.plannedFromDate <= targetDate && task.plannedToDate >= targetDate) {
                newTasks.push(task);
            }
        });

        newTeams.push({...team, tasks: newTasks, constructionSites: newConstructionSites});
    });

    let copy: DayPlanModel = {...dayPlan, teams: newTeams, date: targetDate};

    await addDayPlan(copy)
    return copy
};


//V2 laden
//wenn nix gefunden
//v1 laden
//wenn nix dann leer => []
//sonst v1 -> in v2 speichern
const getDayPlansForRange = async (dateFrom: number, dateTo: number): Promise<DayPlanModel[]> => {
    const firebase = getFirebase();
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {

        /*TODO Evaluate whether using a collection group is suitable for our application

           using a collection group requires us to permission with wildcard in firestore security rules
          like this "match /{path=**}/tokens/{userID}" as this feature will look on every Level for a Teams
          folder.

          Therefore we couldn't host two companies or locations of a customer in a single firebase tenant
          Pros collection group:
            -only one call
            -will be fucking fast
          Cons:
            -Running multiple companies in a single tenant is not possible

          Currently known other solutions:
          1. move this code to a cloud function, query everything, remove anything that doesn't belong to the calling users company and permission level
          2. Split it to separate queries.
                a) Query every DayPlan that meets our constraints
                b) group it in chunks of 10
                c) resolve every chunk separately

         */
        const q = dbConnection.collectionGroup("Teams")
            .where("date", ">=", dateFrom).where("date", "<=", dateTo)

        const querySnapshot = await q.get();

        const teamByDateMap = new Map<number, DayPlanV2TeamModel[]>()

        querySnapshot.forEach((snapshot) => {
            const team: DayPlanV2TeamModel = snapshot.data() as DayPlanV2TeamModel;

            const list = teamByDateMap.get(team.date)
            if (list) {
                teamByDateMap.set(team.date, [...list, team])
            } else {
                teamByDateMap.set(team.date, [team])
            }
        })

        const dayPlans: DayPlanModel[] = [];
        teamByDateMap.forEach((teams, date) => {
            dayPlans.push(transformV2DayPlanToV1DataModel(date, teams))
        })

        if (dayPlans.length > 0) {
            return dayPlans;
        } else {
            const dayPlansOld = await getDayPlansForRangeV1(dateFrom, dateTo)
            if (dayPlansOld.length > 0) {
                dayPlansOld.forEach(async dayPlan => {
                    await addDayPlan(dayPlan)
                })
                return dayPlansOld;
            } else {
                return [];
            }
        }
    }
    return []
}


const transformV2DayPlanToV1DataModel = (date: number, teams: DayPlanV2TeamModel[]): DayPlanModel => {
    return {
        date: date,
        teams: teams.map((teamV2) => {
            return {
                id: teamV2.id,
                name: teamV2.name,
                vehicles: teamV2.vehicles as unknown as VehicleModel[],
                employees: teamV2.employees as unknown as EmployeeModel[],
                equipment: teamV2.equipment as unknown as EquipmentModel[],
                foremans: teamV2.foremans as unknown as EmployeeModel [],
                tasks: teamV2.tasks as unknown as TaskModel[],
                constructionSites: teamV2.constructionSites as unknown as ConstructionSiteModel[]
            } as unknown as TeamModel
        })


    } as DayPlanModel
}

const getDayPlansForDay = async (date: number): Promise<DayPlanModel[]> => {
    const firebase = getFirebase();
    const dbConnection = firebase.firestore();

    let companyId = await getCompanyAffiliation();
    if (companyId !== undefined) {
        const dayPlanRef = dbConnection
            .collection(companyId)
            .doc("DayPlanV2")
            .collection("DayPlanV2")
            .doc(utcTimestampToFormattedISOString(date))

        const dayPlanData = (await dayPlanRef.get()).data();

        const teamCollectionRef = dayPlanRef.collection("Teams")
        const teamsCollection = await teamCollectionRef.get();

        if (dayPlanData === undefined) {
            const dayPlanOld = await getDayPlansForDayV1(date)
            if (dayPlanOld.length > 0) {
                await addDayPlan(dayPlanOld[0])
                return dayPlanOld;
            } else {
                return [];
            }
        }

        const teamsV2Model: DayPlanV2TeamModel[] = []
        teamsCollection.forEach((snapshot) => {
            teamsV2Model.push(snapshot.data() as DayPlanV2TeamModel)
        })

        return [
            transformV2DayPlanToV1DataModel(date, teamsV2Model)
        ]
    }

    return []
};


export {addDayPlan, deleteDayPlan, copyDayPlanToDate, getDayPlansForDay, getDayPlansForRange, resolveTeamView};
